import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
const RegisterUsers = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)

    let [firstName, setFirstName] = useState('')
    let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')
    let [position, setPosition] = useState('')

    const handleFormSubmit = async e => {
            var formData = new FormData(this)
            formData.append('apiType', 'registerUsers')
            formData.append('firstName', firstName)
            formData.append('lastName', lastName)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)
            formData.append('position', position)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/registerUsers.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              //setBusinessNameSuperAdminDropDown(response.data)
              if(response.data === 'User Registered successfully'){
              setFirstName('')
              setLastName('')
              setPhoneNumber('')
              setEmail('')
              setPosition('')
            }
              if(response.data != ''){
              alert(response.data)
            }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
         
      };

  return (
    <section className=''>
      
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
                <Navbar />
                  <div className='mt-1'>
                    <h5 className='font-weight-500 my-1 py-1'>
                      Register Users
                    </h5>
                  </div>
                  <div class='text-center'>
                    <hr class='mt-2' />
                    <MDBRow className='row g-3'>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12' >
        <MDBInput
          id='validationCustom05'
          onChange={e => setPosition(e.target.value)}
          value={position}
          label='Position'
        />
      </MDBCol>
      <div className='col-12'>
        <MDBBtn onClick={handleFormSubmit}
        disabled={firstName === '' || lastName === '' ||  phoneNumber === '' || email === '' || position === ''}
        >Submit</MDBBtn>
      </div>
    </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterUsers
