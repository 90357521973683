import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow,
    MDBRadio, MDBBtnGroup } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
const UpdateProfile = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
userType = JSON.parse(userType)

var userID = localStorage.getItem('userID')
userID = JSON.parse(userID)
//alert(userID)
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/

let [changePassword, setChangePassword] = useState('')
    let [participantID, setParticipantID] = useState('1')
    let [firstName, setFirstName] = useState('')
    let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [comfirmPassword, setComfirmPassword] = useState('')
    const [newPasswordFocus, setNewPasswordFocus] = useState(false)
    const [comfirmPasswordFocus, setComfirmPasswordFocus] = useState(false)
   const [validOldPassword, setValidOldPassword] = useState(false)
    const [validNewPassword, setValidNewPassword] = useState(false)
     const [validComfirmPassword, setValidComfirmPassword] = useState(false)

     useEffect(() => {
        const result = PWD_REGEX.test(newPassword)
        setValidNewPassword(result)
      }, [newPassword])
    
       useEffect(() => {
        const result = PWD_REGEX.test(comfirmPassword)
        if(comfirmPassword === newPassword){
        setValidComfirmPassword(result)
        }
        else{
          setValidComfirmPassword(false)
        }
      }, [comfirmPassword, newPassword])

      useEffect(() => {
        setNewPassword('');
    setComfirmPassword('');
     var formData = new FormData(this)
              formData.append('apiType', 'comfirmOldPassword')
              formData.append('id', userID)
              formData.append('password', oldPassword)
              formData.append('userType', userType)
              Axios({
                method: 'post',
                url: `${server_app_api_php}/reactphp/index.php`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
              }).then(response => {
                if(response.data === 'Correct Password'){
                setValidOldPassword(true)
                }
                else if(response.data === 'Wrong Password'){
                setValidOldPassword(false)
                }
                }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
           
        
      }, [oldPassword])

    const handleFormSubmit = async e => {
            var formData = new FormData(this)
            formData.append('apiType', 'updateProfile')
            formData.append('firstName', firstName)
            formData.append('lastName', lastName)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)
            formData.append('id', userID)
            formData.append('userType', userType)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/index.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              alert(response.data)
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
         
      };  
      
      const handleFormSubmitPassword = async e => {
        var formData = new FormData(this)
        formData.append('apiType', 'updateProfilePassword')
        formData.append('password', newPassword)
        formData.append('id', userID)
        formData.append('userType', userType)
        Axios({
          method: 'post',
          url: `${server_app_api_php}/reactphp/index.php`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => {
            setOldPassword('')
          alert(response.data)
        }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
     
  };

      useEffect(() => {
        var formData = new FormData(this)
          formData.append('apiType', 'getParticipantprofile')
          formData.append('id', userID)
          formData.append('userType', userType)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/reactphp/index.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
           // alert(response.data)
            setFirstName(response.data.firstName)
            setLastName(response.data.lastName)
            setPhoneNumber(response.data.phoneNumber)
            setEmail(response.data.email)
        }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
    }, [])

    useEffect(() => {
setNewPassword('')
setComfirmPassword('')
    }, [oldPassword])

    useEffect(() => {
        setComfirmPassword('')
            }, [newPassword])

  return (
    <section className=''>
      
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
                <Navbar />
                  <div className='mt-1'>
                    <h5 className='font-weight-500 my-1 py-1'>
                      User Profile
                    </h5>
                  </div>
                  <div class='text-center'>
                    <hr class='mt-2' />
                    <MDBRow className='row g-3' hidden={changePassword === 'Yes'}>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
        />
      </MDBCol>
      <div className='col-12'>
        <MDBBtn onClick={handleFormSubmit}
        disabled={firstName === '' || lastName === '' ||  phoneNumber === '' || email === ''}
        >Update</MDBBtn>
      </div>
      </MDBRow>

      <MDBRow className='row g-3' hidden={changePassword === 'No' || changePassword === '' || changePassword === 'undefined' || changePassword === undefined}>
      <MDBCol className='col-md-12 col-12'>
        <MDBInput
        type='password'
          id='validationCustom01'
          value={oldPassword}
        onChange={e => setOldPassword(e.target.value)}
          label='Enter Old Password'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
        type='password'
          id='validationCustom02'
          value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        disabled = {!validOldPassword}
          label='Enter New Password'

          aria-invalid={validNewPassword ? 'false' : 'true'}
          aria-describedby='newPasswordNote'
          onFocus={() => setNewPasswordFocus(true)}
          onBlur={() => setNewPasswordFocus(false)}
        />
         <p
         className='red-text'
         hidden = {newPasswordFocus === false || validNewPassword === true}
         >                                    
                                    Password Should Contain 8 Or More Values. It Should Consist Of :
                                   <br /> Atleast one(1) Capital Letter
                                   <br /> Atleast One(1) Special Character
                                   <br /> and atleast (1) Number  
                                  </p>

      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
        type='password'
          id='validationCustom02'
          value={comfirmPassword}
          onChange={e => setComfirmPassword(e.target.value)}
          disabled = {!validOldPassword || !validNewPassword}
          label='Repeat New Password'

          aria-invalid={validComfirmPassword ? 'false' : 'true'}
                                    aria-describedby='comfirmPasswordNote'
                                    onFocus={() => setComfirmPasswordFocus(true)}
                                    onBlur={() => setComfirmPasswordFocus(false)}
        />
         <p
         className='red-text'
         hidden = {comfirmPasswordFocus === false || validComfirmPassword === true}
         >  
          Repeat Password Should Be the Same As New Password, It Should Contain 8 Or More Values and Consist Of :
                                   <br /> Atleast one(1) Capital Letter
                                   <br /> Atleast One(1) Special Character
                                   <br /> and atleast (1) Number  
                                  </p>
      </MDBCol>
      <div className='col-12'>
        <MDBBtn onClick={handleFormSubmitPassword}
        disabled={!validComfirmPassword || comfirmPassword === ''}
        >Update Password</MDBBtn>
      </div>
      </MDBRow>

      <MDBRow className='row g-3'>
      <MDBBtnGroup>
        Change Password
      <MDBRadio name='inlineRadio' id='inlineRadio1' value='Yes' label='Yes' inline  onChange={e => setChangePassword(e.target.value)} />
      <MDBRadio name='inlineRadio' id='inlineRadio2' value='No' label='No' inline onChange={e => setChangePassword(e.target.value)} />
    </MDBBtnGroup>
    </MDBRow>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UpdateProfile
