import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom'
function Logout() {
 
    let navigate = useNavigate()

   sessionStorage.removeItem('loggedIn');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userType');
    localStorage.removeItem('userID');
    localStorage.removeItem('name');
    localStorage.removeItem('position');
  

  useEffect(()=>{
        navigate('/')
  },[])
      
}

export default Logout;
