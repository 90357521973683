import React, { useState, useEffect } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'
import ReactFlagsSelect from "react-flags-select";

import {   MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBTextArea,MDBDropdown, MDBDropdownItem, MDBDropdownMenu
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import axios from 'axios'
//const axios = require('axios');

function CompanyRegistrationPayment() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });

   const MOMO_REGEX = /^[0-9]{10}$/
   const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

let companyRegistrationPaymentMessage = '';
companyRegistrationPaymentMessage = localStorage.getItem('companyRegistrationPaymentMessage')
companyRegistrationPaymentMessage = JSON.parse(companyRegistrationPaymentMessage)
if(companyRegistrationPaymentMessage === 'undefined'){
  companyRegistrationPaymentMessage = '';
}

let tierSelected = '';
tierSelected = localStorage.getItem('tier')
tierSelected = JSON.parse(tierSelected)

let [countryDB, setcountryDB] = useState('');
let [messages, setMessages] = useState('')
let [message, setMessage] = useState('')
   const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [pacraID, setPacraID] = useState('')
  const [tier, setTier] = useState('')
  const [ip, setIP] = useState('')
  const [state, setState] = useState('')
  let [organisation, setOrganisation] = useState('')
  let [paymentType, setPaymentType] = useState('')
  let [currency, setCurrency] = useState('')
  let [momo, setMomo] = useState('')
  const [validMomo, setValidMomo] = useState(false)
  const [momoFocus, setMomoFocus] = useState(false)
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  /*const [logoFile, setLogoFile] = useState()
  const [logoFileName, setLogoFileName] = useState('')
  const [pacraCertificateFile, setPacraCertificateFile] = useState()
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState('')*/

  
  useEffect(() => {
    const result = MOMO_REGEX.test(momo)
    setValidMomo(result)
  }, [momo])

   
  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])
  
  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  /*const saveLogoFile = e => {
    setLogoFile(e.target.files[0])
    setLogoFileName(e.target.files[0].name)
  }

  const savePacraCertificateFile = e => {
    setPacraCertificateFile(e.target.files[0])
    setPacraCertificateFileName(e.target.files[0].name)
  }*/
  const handleSubmitClear = async e => {
    e.preventDefault()
    localStorage.removeItem('companyRegistrationPaymentMessage')
    setcountryDB('')
setMessages('')
setMessage('')
  setFirstName('')
  setLastName('')
  setAddress('')
  setTier('')
  setState('')
  setOrganisation('')
  setPaymentType('')
  setCurrency('')
 setMomo('')
  setPhone('')
setEmail('')

  };

  const handleSubmit = async e => {
    e.preventDefault()
    setMessage('')
    setMessages('')
    //localStorage.removeItem('companyRegistrationPaymentMessage')
    var formData = new FormData(this)
    
    /*formData.append('logoFile', logoFile)
    formData.append('logoFileName', logoFileName)
    formData.append('pacraCertificateFile', pacraCertificateFile)
    formData.append('pacraCertificateFileName', pacraCertificateFileName)*/

    formData.append('transactionName', 'Kanch Tech Company Registration')
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('tier', tier)
    formData.append('address', address)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('state', state)
    formData.append('organisation', organisation)
    formData.append('paymentType', paymentType)
    formData.append('currency', currency)
    formData.append('momo', momo)
    formData.append('ip', ip)

    var urlLink = '';
    var urlLinkVerify = '';
if(paymentType ==='VISA'){
urlLink = `${server_app_api_php}/reactphp/broadpay/upload.php`;
urlLinkVerify = `${server_app_api_php}/reactphp/broadpay/verify_pay.php`;
}
else if(paymentType ==='Mobile Money'){
    urlLink = `${server_app_api_php}/reactphp/ewallet/request.php`;
    urlLinkVerify = `${server_app_api_php}/reactphp/ewallet/verify.php`;
    }
//alert(urlLink)

    axios({
      method: 'post',
      url: urlLink,
      //url: `${server_app_api_php}/reactphp/registerBusiness.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        //alert(response.data)
        if(response.data.isError === false){
          //setMessage('')
            const interval = setInterval(() => { 
            var formDataverify = new FormData(this)
            formDataverify.append('transactionRef', response.data.reference)
            axios({
                method: 'GET',
                url: urlLinkVerify+'?transactionRef='+response.data.transactionReference,
                data: formDataverify,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(function (res) {
                //alert(res.data.message)
                setMessage(res.data.message)
                localStorage.setItem('companyRegistrationPaymentMessage', JSON.stringify(res.data.message))
                //$('#divStatusMessage').show();
                //$('#divStatusMessage').html(res.data.message);
                if(res.data.message ==='Transaction was successful'){
                  /* $('#divStatusMessageSuccess').show();
                    $('#divStatusMessageFail').hide();
                    $('#divStatusMessagePending').hide();*/
                   /* $('#divStatusMessage').show();
                $('#divStatusMessage').html('Transaction Was Successful');*/

                var formDataUpload = new FormData(this)
                formDataUpload.append('apiType', 'uploadPaymentCompanyRegistration')
                formDataUpload.append('transactionName', 'Kanch Tech Company Registration')
                formDataUpload.append('firstName', firstName)
                formDataUpload.append('lastName', lastName)
                formDataUpload.append('tier', tier)
                formDataUpload.append('address', address)
                formDataUpload.append('phone', phone)
                formDataUpload.append('email', email)
                formDataUpload.append('state', state)
                formDataUpload.append('organisation', organisation)
                formDataUpload.append('paymentType', paymentType)
                //formDataUpload.append('currency', currency)
                formDataUpload.append('momo', momo)
                formDataUpload.append('ip', ip)

                formDataUpload.append('amount', res.data.amount)
                formDataUpload.append('feeAmount', res.data.feeAmount)
                formDataUpload.append('transactionAmount', res.data.transactionAmount)
                formDataUpload.append('currency', res.data.currency)
                formDataUpload.append('customerFirstName', res.data.customerFirstName)
                formDataUpload.append('customerLastName', res.data.customerLastName)
                formDataUpload.append('customerMobileWallet', res.data.customerMobileWallet)
                formDataUpload.append('feePercentage', res.data.feePercentage)
                formDataUpload.append('merchantReference', res.data.merchantReference)
                formDataUpload.append('reference', res.data.reference)
                axios({
                  method: 'post',
                  url: `${server_app_api_php}/reactphp/index.php`,
                  data: formDataUpload,
                  headers: { 'Content-Type': 'multipart/form-data' }
                })


                }
               if(res.data.message === 'Transaction was unsuccessful'){
                  /* $('#divStatusMessageFail').show();
                    $('#divStatusMessageSuccess').hide();
                    $('#divStatusMessagePending').hide();*/
                    /*$('#divStatusMessage').show();
                $('#divStatusMessage').html('Transaction Was Unsuccessful');*/

                
                }
               if(res.data.message === 'Transaction pending authorization.'){
                   /* $('#divStatusMessagePending').show();
                    $('#divStatusMessageSuccess').hide();
                    $('#divStatusMessageFail').hide();*/
                   /* $('#divStatusMessage').show();
                $('#divStatusMessage').html('Transaction Pending Authorization');*/
                }
            })
            .catch(function (res) {
              //handle error
              alert('ERROR : We Can Not Verify Payment, Check Your Internet Connection. If This Persists,  Contact Us On The Numbers Below')
            })
        }, 5000); 
        }
        else{
            alert('ERROR : Check Your Internet Connection. If This Persists,  Contact Us On The Numbers Below')
        }
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Check Your Internet Connection. If This Persists,  Contact Us On The Numbers Below')
      })
  }

  useEffect(() => {
    setMessages(message)
    //setMessage('')
  }, [])
  
  return (
    <>
   
      <Navbar />

      <section class="">
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={50} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      </div>
      </div>
      </section>

{/*<!--Main content-->*/}
<main>


<div class="container">

{/* <!-- Intro Section --> */}
    <section class="view">
    
      <div class="">
        <div class="">

        {/*   <!-- Grid row --> */}
          <div class="row pt-1">

           {/*  <!-- Grid column --> */}
            <div class="col-md-12">

              <div class="card">
                <div class="card-body">

                  <h2 class="font-weight-bold my-4 text-center mb-5 mt-4 font-weight-bold">
                    <strong>MAKE PAYMENT</strong>
                  </h2>
{/*
<div class="alert alert-success py-2 info-color white-text d-flex justify-content-center" > <button type="button" class="close" data-dismiss="alert">&times;</button> {companyRegistrationPaymentMessage}</div> 


<div class="alert alert-success py-2 info-color white-text d-flex justify-content-center" > <button type="button" class="close" data-dismiss="alert">&times;</button> {message}</div> 
<div class="alert alert-success" id="divStatusMessage" style={{display: 'none'}}><button type="button" class="close" data-dismiss="alert">&times;</button></div>

*/}

{ companyRegistrationPaymentMessage === 'Transaction was successful' ? (
  <>
<div class="alert alert-success success-color white-text"  ><button type="button" class="close" data-dismiss="alert">&times;</button>Transaction Was Successful</div>
</>
) : (<></>)
}

{ companyRegistrationPaymentMessage === 'Transaction was unsuccessful' ? (
  <>
<div class="alert alert-danger danger-color white-text"  ><button type="button" class="close" data-dismiss="alert">&times;</button>Transaction Was Unsuccessful</div>

</>
) : (<></>)
}

{ companyRegistrationPaymentMessage === 'Transaction pending authorization.' ? (
  <>
<div class="alert alert-primary primary-color white-text"  ><button type="button" class="close" data-dismiss="alert">&times;</button>Transaction Pending Authorization.</div>
</>
) : (<></>)
}
{/*
<div class="alert alert-success success-color white-text" id="divStatusMessageSuccess" style={{display: 'none'}}><button type="button" class="close" data-dismiss="alert">&times;</button>{companyRegistrationPaymentMessage}</div>
<div class="alert alert-danger danger-color white-text" id="divStatusMessageFail" style={{display: 'none'}}><button type="button" class="close" data-dismiss="alert">&times;</button>{companyRegistrationPaymentMessage}</div>
<div class="alert alert-primary primary-color white-text" id="divStatusMessagePending" style={{display: 'none'}}><button type="button" class="close" data-dismiss="alert">&times;</button>{companyRegistrationPaymentMessage}</div>
	*/}
                  <hr />

                 {/*  <!-- Grid row --> */}
                  <div class="row mt-2">

                  {/*   <!-- Grid column --> */}
                    <div class="card col-md-12" >

                  {/*     <!-- Grid row --> */}
                      <div class="row d-flex justify-content-center">

                        <h4 class="d-flex justify-content-center mt-2">
                          <strong></strong>
                        </h4>

                      </div>
                      <MDBRow className='row g-3'>      

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email Address'
          onFocus={() => setEmailFocus(true)}
          onBlur={() => setEmailFocus(false)}
        />
        <p
          class='red-text'
          hidden={
            emailFocus === false || validEmail === true
          }
        >
          <i class='fa fa-info-circle'></i>
         Invalid Email Format
        </p>
      </MDBCol>

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          value={phone}
          label='Phone Number'
          onFocus={() => setPhoneFocus(true)}
          onBlur={() => setPhoneFocus(false)}
        />
        <p
          class='red-text'
          hidden={
            phoneFocus=== false || validPhone=== true
          }
        >
          <i class='fa fa-info-circle' />
          invalid Phone Number
        </p>
      </MDBCol>


      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          label='First name'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
      <select
                            class='custom-select d-block w-100'
                            onChange={e => setTier(e.target.value)}
                            value={tier}
                          >
                            <option value=''>Choose Tier...</option>
                            <option  hidden={tierSelected === null || tierSelected=== ''}>{tierSelected}</option>
                            <option hidden={tierSelected != null || tierSelected=== ''}>Silver Tier</option>
                            <option hidden={tierSelected != null || tierSelected=== ''}>Gold Tier</option>
                            <option hidden={tierSelected !=null || tierSelected=== ''}>Platinum Tier</option>
                          </select>
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
      <ReactFlagsSelect
      searchable
    selected={countryDB}
    value={countryDB}
    onSelect={(code) => setcountryDB(code)}
  />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setState(e.target.value)}
          value={state}
          label='State'
        />
      </MDBCol>      

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setOrganisation(e.target.value)}
          value={organisation}
          label='Organization'
        />
      </MDBCol>

      <MDBCol className='col-md-6 col-12'>
      <select
                            class='custom-select d-block w-100'
                            onChange={e => setPaymentType(e.target.value)}
                            value={paymentType}
                          >
                            <option value=''>Choose Payment Method...</option>
                            <option>VISA</option>
                            <option>Mobile Money</option>
                          </select>
      </MDBCol>

      <MDBCol className='col-md-6 col-12'>
      <select
                            class='custom-select d-block w-100'
                            onChange={e => setCurrency(e.target.value)}
                            value={currency}
                          >
                            <option value=''>Choose Currency...</option>
                            <option>USD</option>
                            <option>ZMW</option>
                          </select>
      </MDBCol>

      <MDBCol className='col-md-12 col-lg-12' hidden={countryDB != 'ZM' || paymentType !='Mobile Money'}>
        <MDBInput
          id='validationCustom01'
          onChange={e => setMomo(e.target.value)}
          value={momo}
          label='Mobile Money Wallet Phone Number'
          onFocus={() => setMomoFocus(true)}
          onBlur={() => setMomoFocus(false)}
        />
        <p
          class='red-text'
          hidden={
            momoFocus=== false || validMomo=== true
          }
        >
          <i class='fa fa-info-circle' />
          invalid Mobile Money Phone Number. It Should Not Contain Area Code!
        </p>
      </MDBCol>

      <MDBCol className='col-md-12 col-lg-12'>
        <MDBTextArea label='Physical Address' id='textAreaExample' rows={4} 
        onChange={e => setAddress(e.target.value)}
        value={address}
        />
      </MDBCol>

                      </MDBRow>

                      <div class="text-center">
                        <button class="btn btn-info mt-2"
                             onClick={handleSubmitClear}
                             disabled={
                               phone === '' &&
                               email === '' && 
                               firstName ==='' && lastName === '' && tier ==='' && countryDB ==='' &&
                               state ==='' && organisation ==='' && address ==='' && 
                               momo === ''
                             }
                        >Clear</button>
                        <button class="btn btn-blue mt-2"
                             onClick={handleSubmit}
                             disabled={
                               !validPhone ||
                               !validEmail || 
                               firstName ==='' || lastName === '' || tier ==='' || countryDB ===''||
                               state ==='' || organisation ==='' || address ==='' || 
                               countryDB === 'ZM' && (!validMomo)
                             }
                        >Pay</button>
                      </div>

                    </div>
                {/*     <!-- Grid column --> */}

                  </div>
              {/*     <!-- Grid row --> */}

                </div>
              </div>

            </div>
          {/*   <!-- Grid column --> */}

          </div>
        {/*   <!-- Grid row --> */}

        </div>
      </div>
    </section>
   {/* <!-- Intro Section --> */}

</div>



</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default CompanyRegistrationPayment;
