import logo from './logo.svg'
import './App.css'

import HomePage from './components/HomePage'
import CompanyRegistration from './components/CompanyRegistration'
import Services from './components/Services'
import OnlinePointOfSale from './components/OnlinePointOfSale'
import SystemsDevelopment from './components/SystemsDevelopment'
import CompanyRegistrationPayment from './components/CompanyRegistrationPayment'
import PaymentSuccessCompanyRegistration from './components/PaymentSuccessCompanyRegistration'
import RegisterUsers from './components/RegisterUsers'
import AddParticipants from './components/AddParticipants'
import ViewIntrestedParticipants from './components/ViewIntrestedParticipants'
import UpdateProfile from './components/UpdateProfile'
import CompleteRegistration from './components/CompleteRegistration'
import ViewPaidParticipants from './components/ViewPaidParticipants'
import PrintAttendanceID from './components/PrintAttendanceID'
import AddBulkyPayment from './components/AddBulkyPayment'
import Logout from './components/Logout'
import ApplicationForm from './components/ApplicationForm'
import RegisterParticipant from './components/RegisterParticipant'
import Missing from './404/Missing'
import { Routes, Route } from 'react-router-dom'

function App () {
  return (
    <Routes>
      <Route>
        {/* Public Routes*/}
        {/* We want To Protect These Routes*/}
        <Route path='/' element={<HomePage />} />
        <Route path='CompanyRegistration' element = {<CompanyRegistration />} />
        <Route path='Services' element = {<Services />} />
        <Route path='OnlinePointOfSale' element={<OnlinePointOfSale />} />
        <Route path='SystemsDevelopment' element={<SystemsDevelopment />} />
        <Route path='CompanyRegistrationPayment' element={<CompanyRegistrationPayment />} />
        <Route path='PaymentSuccessCompanyRegistration' element={<PaymentSuccessCompanyRegistration />} />
        <Route path='RegisterUsers' element={<RegisterUsers />} />
        <Route path='AddParticipants' element={<AddParticipants />} />
        <Route path='ViewIntrestedParticipants' element={<ViewIntrestedParticipants />} />
        <Route path='UpdateProfile' element={<UpdateProfile />} />
        <Route path='CompleteRegistration' element={<CompleteRegistration />} />
        <Route path='ViewPaidParticipants' element={<ViewPaidParticipants />} />
        <Route path='PrintAttendanceID' element={<PrintAttendanceID />} />
        <Route path='AddBulkyPayment' element={<AddBulkyPayment />} />
        <Route path='ApplicationForm' element={<ApplicationForm />} />
        <Route path='RegisterParticipant' element={<RegisterParticipant />} />
        <Route path='Logout' element={<Logout />} />
        {/* catch all*/}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
