import React, { useState, useEffect } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'
import ReactFlagsSelect from "react-flags-select";

import {   MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBTextArea,MDBDropdown, MDBDropdownItem, MDBDropdownMenu
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import axios from 'axios'
//const axios = require('axios');

function SystemsDevelopment() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });

   const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

let systemsDevelopment = "";
systemsDevelopment = localStorage.getItem('systemsDevelopment')
systemsDevelopment = JSON.parse(systemsDevelopment)

let [countryDB, setcountryDB] = useState('');

   const [BusinessName, setBusinessName] = useState('')
  const [BusinessID, setBusinessID] = useState('')
  const [branch, setBranch] = useState('')
  const [pacraID, setPacraID] = useState('')
  const [address, setAddress] = useState('')
  const [ip, setIP] = useState('')
  const [shopType, setShopType] = useState('')
  let [businessCategory, setBusinessCategory] = useState('')
  //const [businessCategoryDropDown, setBusinessCategoryDropDown] = useState([])
  const [otherShopCategory, setOtherShopCategory] = useState('')
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  const [logoFile, setLogoFile] = useState()
  const [logoFileName, setLogoFileName] = useState('')
  const [pacraCertificateFile, setPacraCertificateFile] = useState()
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState('')

  
  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])
  
  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  const saveLogoFile = e => {
    setLogoFile(e.target.files[0])
    setLogoFileName(e.target.files[0].name)
  }

  const savePacraCertificateFile = e => {
    setPacraCertificateFile(e.target.files[0])
    setPacraCertificateFileName(e.target.files[0].name)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    var formData = new FormData(this)
    
    formData.append('logoFile', logoFile)
    formData.append('logoFileName', logoFileName)
    formData.append('pacraCertificateFile', pacraCertificateFile)
    formData.append('pacraCertificateFileName', pacraCertificateFileName)

    formData.append('businessName', BusinessName)
    formData.append('businessID', BusinessID)
    formData.append('pacraID', pacraID)
    formData.append('address', address)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('businessCategory', businessCategory)
    formData.append('shopType', shopType)
    formData.append('otherShopCategory', otherShopCategory)
    formData.append('system_setup_IP', ip)

    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/registerBusiness.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  
  return (
    <>
   
      <Navbar />

      <section class="">
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={50} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      </div>
      </div>
      </section>

{/*<!--Main content-->*/}
<main>


<div class="container">

{/* <!-- Intro Section --> */}
    <section class="view">
      <div class="">
        <div class="">

        {/*   <!-- Grid row --> */}
          <div class="row pt-1">

           {/*  <!-- Grid column --> */}
            <div class="col-md-12">

              <div class="card">
                <div class="card-body">

                  <h2 class="font-weight-bold my-4 text-center mb-5 mt-4 font-weight-bold">
                    <strong>SYSTEMS DEVELOPMENT</strong>
                  </h2>
                  <hr />

                 {/*  <!-- Grid row --> */}
                  <div class="row mt-5">

                   {/*  <!-- Grid column --> */}
                    <div class="col-md-12 ml-lg-12 ml-md-12">

                      {/* <!-- Grid row --> */}
                      <div class="row pb-4" hidden = {systemsDevelopment != 'ERP Web-based and Standalone Systems'}>
                    <div class="col-lg-12 col-md-12 mb-4">
            <div class="card card-cascade narrower">
              <div class="view view-cascade gradient-card-header blue-gradient">

                <h2 class="card-header-title">{systemsDevelopment}</h2>
              </div>
              <div class="card-body card-body-cascade text-center">
                  <ul class="navbar-nav mr-auto card-text text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> ERP Web-based and Standalone Systems Development</li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Mobile App Development  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>3.</span> E-commerce System Solutions </li>
            </ul>

              </div>
              </div>
            </div>
                      </div>
                    {/*   <!-- Grid row --> */}

                    {/*   <!-- Grid row --> */}
                      <div class="row pb-4" hidden = {systemsDevelopment != 'Mobile Applications'}>
                      <div class="col-lg-12 col-md-12 mb-4">
            <div class="card card-cascade narrower">
              <div class="view view-cascade gradient-card-header blue-gradient">

                <h2 class="card-header-title">{systemsDevelopment}</h2>
              </div>
              <div class="card-body card-body-cascade text-center">
                  <ul class="navbar-nav mr-auto card-text text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> Android Mobile Apps Development  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> IOS Mobile Apps Development </li>
            </ul>

              </div>
              </div>
            </div>
                      </div>
                    {/*   <!-- Grid row --> */}

                   {/*    <!-- Grid row --> */}
                      <div class="row pb-4" hidden = {systemsDevelopment != 'Websites'}>
                      <div class="col-lg-12 col-md-12 mb-4">
            <div class="card card-cascade narrower">
              <div class="view view-cascade gradient-card-header blue-gradient">

                <h2 class="card-header-title">{systemsDevelopment}</h2>
              </div>
              <div class="card-body card-body-cascade text-center">
                  <ul class="navbar-nav mr-auto card-text text-left">
            </ul>

              </div>
              </div>
            </div>                      </div>
                   {/*    <!-- Grid row --> */}

                                      {/*    <!-- Grid row --> */}
                                      <div class="row pb-4" hidden = {systemsDevelopment != 'Networking Services'}>
                                      <div class="col-lg-12 col-md-12 mb-4">
            <div class="card card-cascade narrower">
              <div class="view view-cascade gradient-card-header blue-gradient">

                <h2 class="card-header-title">{systemsDevelopment}</h2>
              </div>
              <div class="card-body card-body-cascade text-center">
                  <ul class="navbar-nav mr-auto card-text text-left">
                  <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> Networking Services</li>
                <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Cyber Security Services And Consultations</li>
            </ul>

              </div>
              </div>
            </div>
                      </div>
                   {/*    <!-- Grid row --> */}

                       {/*    <!-- Grid row --> */}
                       <div class="row pb-4" hidden = {systemsDevelopment != 'ICT Equipment Supply and Installation'}>
                       <div class="col-lg-12 col-md-12 mb-4">
            <div class="card card-cascade narrower">
              <div class="view view-cascade gradient-card-header blue-gradient">

                <h2 class="card-header-title">{systemsDevelopment}</h2>
              </div>
              <div class="card-body card-body-cascade text-center">
                  <ul class="navbar-nav mr-auto card-text text-left">
                  <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> ICT Equipment Supply and Installation</li>
                <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> ICT Infrustracture Consultations</li>
            </ul>

              </div>
              </div>
            </div>
                      </div>
                   {/*    <!-- Grid row --> */}

                    </div>
                   {/*  <!-- Grid column --> */}

                  {/*   <!-- Grid column --> */}
                    <div class="card col-md-12" >

                  {/*     <!-- Grid row --> */}
                      <div class="row d-flex justify-content-center">

                        <h4 class="d-flex justify-content-center mt-2">
                          <strong>APPLY</strong>
                        </h4>

                      </div>
                      <MDBRow className='row g-3'>      

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setEmail(e.target.value)}
          label='Email Address'
          onFocus={() => setEmailFocus(true)}
          onBlur={() => setEmailFocus(false)}
        />
        <p
          class='red-text'
          hidden={
            emailFocus === false || validEmail === true
          }
        >
          <i class='fa fa-info-circle'></i>
         Invalid Email Format
        </p>
      </MDBCol>

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          label='Phone Number'
          onFocus={() => setPhoneFocus(true)}
          onBlur={() => setPhoneFocus(false)}
        />
        <p
          class='red-text'
          hidden={
            phoneFocus=== false || validPhone=== true
          }
        >
          <i class='fa fa-info-circle' />
          invalid Phone Number
        </p>
      </MDBCol>


      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          label='First name'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          label='Last name'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
      <select
                            class='custom-select d-block w-100'
                            onChange={e => setPhone(e.target.value)}
                          >
                            <option value=''>Choose Application...</option>
                            <option>{systemsDevelopment}</option>
                          </select>
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
      <ReactFlagsSelect
      searchable
    selected={countryDB}
    value={countryDB}
    onSelect={(code) => setcountryDB(code)}
  />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          label='State'
        />
      </MDBCol>      

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setPhone(e.target.value)}
          label='Organization'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBTextArea label='Physical Address' id='textAreaExample' rows={4} />
      </MDBCol>

      <MDBCol className='col-md-8 col-12'>
        <MDBTextArea label='Brief Application Explanation' id='textAreaExample' rows={4} />
      </MDBCol>
                      </MDBRow>

                      <div class="text-center">
                        <button class="btn btn-blue mt-2"
                             onClick={handleSubmit}
                             disabled={
                               !validPhone ||
                               !validEmail
                             }
                        >Sign up</button>
                      </div>

                    </div>
                {/*     <!-- Grid column --> */}

                  </div>
              {/*     <!-- Grid row --> */}

                </div>
              </div>

            </div>
          {/*   <!-- Grid column --> */}

          </div>
        {/*   <!-- Grid row --> */}

        </div>
      </div>
    </section>
   {/* <!-- Intro Section --> */}

</div>



</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default SystemsDevelopment;
