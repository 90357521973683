import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
  import ReactDOM from 'react-dom';
//import { PDFViewer, Page, Text, View, Document, StyleSheet  } from '@react-pdf/renderer';
import {QRCodeCanvas} from 'qrcode.react';
import {QRCodeSVG} from 'qrcode.react';
const PrintAttendanceID = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)

    const [handleSubmitBtnLoaderOne, setHandleSubmitBtnLoaderOne] = useState('')
    const [handleSubmitBtnLoader, setHandleSubmitBtnLoader] = useState('')
    let [companyCode, setCompanyCode] = useState('')

    let [name, setName] = useState('')
    let [gender, setGender] = useState('')
    let [country, setCountry] = useState('')
    let [companyCodeDB, setCompanyCodeDB] = useState('')
    let [image, setImage] = useState('')
    //let [imageurl, setImageurl] = useState('')
    //const saveParticipantsFile = e => {
        //setParticipantsFile(e.target.files[0])
      //}

      useEffect(() => {
      //if(handleSubmitBtnLoaderOne === ''){
       //setHandleSubmitBtnLoader('Loading')
      //}
    }, [handleSubmitBtnLoaderOne])

    const handleFormSubmit = async e => {
      setHandleSubmitBtnLoaderOne('Loading')
      
        
            var formData = new FormData(this)
            formData.append('apiType', 'getPrintParticipantIDDetails')
            formData.append('companyCode', companyCode)
            //formData.append('userType', userType)
            //formData.append('id', userID)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/index.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                setHandleSubmitBtnLoader('')
                setCompanyCode('')
              if(response.data === 'Error : No Results Found'){              
                setCompanyCodeDB('')
              alert(response.data)
            }
              else if(response.data != 'Error : No Results Found'){             
                setCompanyCodeDB(response.data)
            }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity")
            );
         
      };

      // Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});



    

      function print () {
        var content = document.getElementById('printarea')
        var pri = document.getElementById('ifmcontentstoprint').contentWindow
        pri.document.open()
        pri.document.write(content.innerHTML)
        pri.document.close()
        pri.focus()
        pri.print()
      }
      let imageurl = '';
      if(userType === 'Participant'){
      imageurl = server_app_api_php+'/reactphp/images/participants/'+ image ;
      }
      else{
        imageurl = server_app_api_php+'/reactphp/images/employees/'+ image ;
      }
      let pdfDocUrl = ''
      pdfDocUrl = server_app_api_php+'/tcpdf/examples/participants ids/'+companyCode+'.pdf' ;

  return (
    <>        

    <section className=''>
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
                <Navbar />
                  <div className='mt-3'>
                    <h5 className='font-weight-500 my-1 py-1'>
                    Print Youth Summit Attendance ID
                    </h5>
                  </div>
                  <div class='text-center'>
                    <hr class='mt-1' />
                    
                     {/*Printing Receipt Starts Here*/}
                  <iframe
                  hidden                 
                    //src={pdfDocUrl}
                    allow="encrypted-media"
                    frameborder="0" height="1000px" width="100%"
                  ></iframe>
      {/*<div id='printarea' hidden = {companyCodeDB === ''}>
      <div className='bg-image'>
      {/*<img src='https://mdbootstrap.com/img/new/standard/city/005.webp' className='w-100' alt='AAAZ' /> */}
      {/*<center><img src={require('./img/aaaz summit.jpg')} className='w-100' width={500} height={500}/> </center>
      <div className='d-flex justify-content-center align-items-center h-100'>
      <center>
      <img src={imageurl} width={200} height={200} />
      <br />
      <strong> {gender} {name}</strong>
      <br />
      <strong>{country}</strong>
      <br />
      <QRCodeSVG value= {companyCodeDB}  />
      <br />
      {companyCodeDB}
      </center>
      </div>
      </div>
      </div>
    */}

                  <button
                  hidden
                      class='btn btn-sm btn-primary'
                      onClick={print}
                     // hidden = {companyCodeDB === ''}
                    >
                      Print ID <i class='fa fa-print'></i>
                    </button>
                  {/*Printing Receipt Ends Here*/}
                


          <MDBRow  className='row g-3 mt-5'>
      <MDBCol className='col-md-12 col-12 text-left'>
        <MDBInput
        type='text'
          id='validationCustom01'
          onChange={e => setCompanyCode(e.target.value)}
        value={companyCode}
        label='Enter Company Code'
          
        />
      </MDBCol>
      
      <MDBCol className='col-md-12 col-12'>
       {/* <MDBBtn onClick={handleFormSubmit}
        disabled={companyCode === ''}
        >
          <div class="spinner-border" role="status" hidden = {handleSubmitBtnLoader === ''}>
</div>
          Submit</MDBBtn> */}
          <a href={pdfDocUrl} disabled={companyCode === ''} target='iframe'>Submit</a>
          </MDBCol> 
    </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default PrintAttendanceID
