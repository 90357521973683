import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBRadio, MDBBtnGroup
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
  import ReactFlagsSelect from "react-flags-select";
const ApplicationForm = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    let [REF, setREF] = useState('')

    useEffect(() => {
      if(REF != ''){
      //setHandleSubmitBtnDB('Loading')

          var formData = new FormData(this)
          formData.append('apiType', 'CheckREFForUpdate')
          formData.append('REF', REF)
          Axios({
            method: 'post',
            url: `${server_app_api_php}/tcpdf/examples/aaazParticipantEmailCheckREF.php`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
            //setHandleSubmitBtnDB('')
            //setBusinessNameSuperAdminDropDown(response.data)
            if(response.data != 'No Results Found'){
              setcategoryDB(response.data.category)
              setfirstNameDB(response.data.firstName)
              setlastNameDB(response.data.lastName)
              setphoneNumberDB(response.data.phoneNumber)
              setemailDB(response.data.email)
              setsexDB(response.data.sex)
              setcountryDB(response.data.country)
              setREFStatus('Correct')
              setREFValidity('')
          }
            else{
              setcategoryDB('')
              setfirstNameDB('')
              setlastNameDB('')
              setphoneNumberDB('')
              setemailDB('')
              setsexDB('')
              setcountryDB('')
              setREFStatus('')
            setREFValidity(response.data)
          }
          }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));      
        }
        else{
          setREF('')
        }
   }, [REF])

    /*var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)*/
    //const [selected, setSelected] = useState("");
    let [handleSubmitBtnUpdate, setHandleSubmitBtnUpdate] = useState('')
    let [handleSubmitBtnDB, setHandleSubmitBtnDB] = useState('')

    let [REFValidity, setREFValidity] = useState('')
    let [REFStatus, setREFStatus] = useState('')
    let [updateProfile, setUpdateProfile] = useState('')
    
    let [passportSizePhotoDB, setpassportSizePhotoDB] = useState('');
    let [categoryDB, setcategoryDB] = useState('');
    let [firstNameDB, setfirstNameDB] = useState('');
    let [lastNameDB, setlastNameDB] = useState('');
    let [phoneNumberDB, setphoneNumberDB] = useState('');
    let [emailDB, setemailDB] = useState('');
    let [sexDB, setsexDB] = useState('');
    let [countryDB, setcountryDB] = useState('');

    let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

    let [passportSizePhoto, setPassportSizePhoto] = useState('')
    let [category, setCategory] = useState('')
    let [firstName, setFirstName] = useState('')
    let [middleName, setmiddleName] = useState('')
    let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')    
    let [age, setage] = useState('')
    let [sex, setsex] = useState('')
    let [whatsAppNumber, setwhatsAppNumber] = useState('')
    let [city, setcity] = useState('')
    let [state, setstate] = useState('')
    let [country, setcountry] = useState('')
    let [occupation, setoccupation] = useState('')
    let [organization, setorganization] = useState('')
    let [sector, setsector] = useState('')
    let [sourceofSponsorship, setsourceofSponsorship] = useState('')
    let [howDidYouHearAboutTheSummit, sethowDidYouHearAboutTheSummit] = useState('')
    let [whyDoYouWantToParticipateInTheSummit, setwhyDoYouWantToParticipateInTheSummit] = useState('')

    const handleFormSubmit = async e => {
      setHandleSubmitBtn('Loading')

            var formData = new FormData(this)
            formData.append('apiType', 'participantImage')
            formData.append('participantFile', passportSizePhoto)
            formData.append('category', category)
            formData.append('firstName', firstName)
            formData.append('middleName', middleName) 
            formData.append('lastName', lastName)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)   
            formData.append('age', age)
            formData.append('sex', sex)
            formData.append('whatsAppNumber', whatsAppNumber)
            formData.append('city', city)
            formData.append('state', state)
            formData.append('Country', country)
            formData.append('occupation', occupation)
            formData.append('organization', organization)
            formData.append('sector', sector)
            formData.append('sourceofSponsorship', sourceofSponsorship)
            formData.append('howDidYouHearAboutTheSummit', howDidYouHearAboutTheSummit)
            formData.append('whyDoYouWantToParticipateInTheSummit', whyDoYouWantToParticipateInTheSummit)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/tcpdf/examples/aaazParticipantEmail.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              setHandleSubmitBtn('')
              //setBusinessNameSuperAdminDropDown(response.data)
              if(response.data === 'Successfully Registered, Check Your Email For The E- ID'){
             setFirstName('') 
              setCategory('') 
              setPassportSizePhoto('')              
              setmiddleName('') 
              setLastName('')
              setPhoneNumber('')
              setEmail('')  
              setage('')
              setsex('')
              setwhatsAppNumber('')
              setcity('')
              setstate('')
              setcountry('')
              setoccupation('')
              setorganization('')
              setsector('')
              setsourceofSponsorship('')
              sethowDidYouHearAboutTheSummit('')
              setwhyDoYouWantToParticipateInTheSummit('')
              alert(response.data)
            }
              else{
              alert(response.data)
            }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
         
      };

      const handleHome = async e => {
        navigate('/')
    };

    const savePassportSizePhoto = e => {
        setPassportSizePhoto(e.target.files[0])
      }

      const savePassportSizePhotoDB = e => {
        setpassportSizePhotoDB(e.target.files[0])
      }
/*
      useEffect(() => {
        if(country != ''){
  
          if(country ==='AF'){
            setcountry("Afghanistan");
            } 
            if(country ===  'AL'){ 
            setcountry("Albania"); 
            } 
            if(country ===  'DZ'){ 
            setcountry("Algeria"); 
            } 
            if(country ===  'AS'){ 
            setcountry("American Samoa"); 
            } 
            if(country ===  'AD'){ 
            setcountry("Andorra"); 
            } 
            if(country ===  'AO'){ 
            setcountry("Angola"); 
            } 
            if(country ===  'AI'){ 
            setcountry("Anguilla"); 
            } if(country ===  'AG'){ 
            setcountry("Antigua and Barbuda"); 
            } 
            if(country ===  'AR'){ 
            setcountry("Argentina"); 
            } 
            if(country ===  'AM'){ 
            setcountry("Armenia"); 
            } 
            if(country ===  'AW'){ 
            setcountry("Aruba"); 
            } 
            if(country ===  'AU'){ 
            setcountry("Australia"); 
            } 
            if(country ===  'AT'){ setcountry("Austria"); } 
            if(country ===  'AZ'){ setcountry("Azerbaijan"); } 
            if(country ===  'BS'){ setcountry("Bahamas"); } 
            if(country ===  'BH'){ setcountry("Bahrain"); } 
            if(country ===  'BD'){ setcountry("Bangladesh"); } 
            if(country ===  'BB'){ setcountry("Barbados"); } 
            if(country ===  'BY'){ setcountry("Belarus"); } 
            if(country ===  'BE'){ setcountry("Belgium"); } 
            if(country ===  'BZ'){ setcountry("Belize"); } 
            if(country ===  'BJ'){ setcountry("Benin"); } 
            if(country ===  'BM'){ setcountry("Bermuda"); } 
            if(country ===  'BT'){ setcountry("Bhutan"); } 
            if(country ===  'BO'){ setcountry("Bolivia, Plurinational State of"); } 
            if(country ===  'BA'){ setcountry("Bosnia and Herzegovina"); } 
            if(country ===  'BW'){ setcountry("Botswana"); } 
            if(country ===  'BR'){ setcountry("Brazil"); } 
            if(country ===  'IO'){ setcountry("British Indian Ocean Territory"); } 
            if(country ===  'BG'){ setcountry("Bulgaria"); } 
            if(country ===  'BF'){ setcountry("Burkina Faso"); } 
            if(country ===  'BI'){ setcountry("Burundi"); } 
            if(country ===  'KH'){ setcountry("Cambodia"); } 
            if(country ===  'CM'){ setcountry("Cameroon"); } 
            if(country ===  'CA'){ setcountry("Canada"); } 
            if(country ===  'CV'){ setcountry("Cape Verde"); } 
            if(country ===  'KY'){ setcountry("Cayman Islands"); } 
            if(country ===  'CF'){ setcountry("Central African Republic"); } 
            if(country ===  'TD'){ setcountry("Chad"); } 
            if(country ===  'CL'){ setcountry("Chile"); } 
            if(country ===  'CN'){ setcountry("China"); } 
            if(country ===  'CO'){ setcountry("Colombia"); } 
            if(country ===  'KM'){ setcountry("Comoros"); } 
            if(country ===  'CG'){ setcountry("Congo"); } 
            if(country ===  'CD'){ setcountry("Democratic Republic of the Congo"); } 
            if(country ===  'CK'){ setcountry("Cook Islands"); } 
            if(country ===  'CR'){ setcountry("Costa Rica"); } 
            if(country ===  'CI'){ setcountry("Côte d'Ivoire"); } 
            if(country ===  'HR'){ setcountry("Croatia"); } 
            if(country ===  'CU'){ setcountry("Cuba"); } 
            if(country ===  'CW'){ setcountry("Curaçao"); } 
            if(country ===  'CY'){ setcountry("Cyprus"); } 
            if(country ===  'CZ'){ setcountry("Czech Republic"); } 
            if(country ===  'DK'){ setcountry("Denmark"); } 
            if(country ===  'DJ'){ setcountry("Djibouti"); } 
            if(country ===  'DM'){ setcountry("Dominica"); } 
            if(country ===  'DO'){ setcountry("Dominican Republic"); } 
            if(country ===  'EC'){ setcountry("Ecuador"); } 
            if(country ===  'EG'){ setcountry("Egypt"); } 
            if(country ===  'SV'){ setcountry("El Salvador"); } 
            if(country ===  'GQ'){ setcountry("Equatorial Guinea"); } 
            if(country ===  'ER'){ setcountry("Eritrea"); } 
            if(country ===  'EE'){ setcountry("Estonia"); } 
            if(country ===  'ET'){ setcountry("Ethiopia"); } 
            if(country ===  'FK'){ setcountry("Falkland Islands (Malvinas)"); }
            if(country ===  'FO'){ setcountry("Faroe Islands"); }
            if(country ===  'FJ'){ setcountry("Fiji"); } 
            if(country ===  'FI'){ setcountry("Finland"); } 
            if(country ===  'FR'){ setcountry("France"); } 
            if(country ===  'PF'){ setcountry("French Polynesia"); } 
            if(country ===  'GA'){ setcountry("Gabon"); } 
            if(country ===  'GM'){ setcountry("Gambia"); } 
            if(country ===  'GE'){ setcountry("Georgia"); } 
            if(country ===  'DE'){ setcountry("Germany"); } 
            if(country ===  'GH'){ setcountry("Ghana"); } 
            if(country ===  'GI'){ setcountry("Gibraltar"); } 
            if(country ===  'GR'){ setcountry("Greece"); } 
            if(country ===  'GL'){ setcountry("Greenland"); } 
            if(country ===  'GD'){ setcountry("Grenada"); } 
            if(country ===  'GU'){ setcountry("Guam"); }
            if(country ===  'GT'){ setcountry("Guatemala"); } 
            if(country ===  'GG'){ setcountry("Guernsey"); } 
            if(country ===  'GN'){ setcountry("Guinea"); } 
            if(country ===  'GW'){ setcountry("Guinea-Bissau"); } 
            if(country ===  'HT'){ setcountry("Haiti"); } 
            if(country ===  'HN'){ setcountry("Honduras"); } 
            if(country ===  'HK'){ setcountry("Hong Kong"); } 
            if(country ===  'HU'){ setcountry("Hungary"); } 
            if(country ===  'IS'){ setcountry("Iceland"); } 
            if(country ===  'IN'){ setcountry("India"); } 
            if(country ===  'ID'){ setcountry("Indonesia"); } 
            if(country ===  'IR'){ setcountry("Iran, Islamic Republic of"); } 
            if(country ===  'IQ'){ setcountry("Iraq"); } 
            if(country ===  'IE'){ setcountry("Ireland"); } 
            if(country ===  'IM'){ setcountry("Isle of Man"); } 
            if(country ===  'IL'){ setcountry("Israel"); } 
            if(country ===  'IT'){ setcountry("Italy"); } 
            if(country ===  'JM'){ setcountry("Jamaica"); } 
            if(country ===  'JP'){ setcountry("Japan"); } 
            if(country ===  'JE'){ setcountry("Jersey"); } 
            if(country ===  'JO'){ setcountry("Jordan"); } 
            if(country ===  'KZ'){ setcountry("Kazakhstan"); } 
            if(country ===  'KE'){ setcountry("Kenya"); } 
            if(country ===  'KI'){ setcountry("Kiribati"); } 
            if(country ===  'KP'){ setcountry("North Korea"); } 
            if(country ===  'KR'){ setcountry("South Korea"); } 
            if(country ===  'KW'){ setcountry("Kuwait"); } 
            if(country ===  'KG'){ setcountry("Kyrgyzstan"); } 
            if(country ===  'LA'){ setcountry("Lao People's Democratic Republic"); }
            if(country ===  'LV'){ setcountry("Latvia"); } 
            if(country ===  'LB'){ setcountry("Lebanon"); } 
            if(country ===  'LS'){ setcountry("Lesotho"); } 
            if(country ===  'LR'){ setcountry("Liberia"); } 
            if(country ===  'LY'){ setcountry("Libya"); } 
            if(country ===  'LI'){ setcountry("Liechtenstein"); } 
            if(country ===  'LT'){ setcountry("Lithuania"); } 
            if(country ===  'LU'){ setcountry("Luxembourg"); } 
            if(country ===  'MO'){ setcountry("Macao"); } 
            if(country ===  'MK'){ setcountry("Republic of Macedonia"); } 
            if(country ===  'MG'){ setcountry("Madagascar"); } 
            if(country ===  'MW'){ setcountry("Malawi"); } 
            if(country ===  'MY'){ setcountry("Malaysia"); } 
            if(country ===  'MV'){ setcountry("Maldives"); } 
            if(country ===  'ML'){ setcountry("Mali"); } 
            if(country ===  'MT'){ setcountry("Malta"); } 
            if(country ===  'MH'){ setcountry("Marshall Islands"); } 
            if(country ===  'MQ'){ setcountry("Martinique"); } 
            if(country ===  'MR'){ setcountry("Mauritania"); } 
            if(country ===  'MU'){ setcountry("Mauritius"); } 
            if(country ===  'MX'){ setcountry("Mexico"); } 
            if(country ===  'FM'){ setcountry("Micronesia, Federated States of"); } 
            if(country ===  'MD'){ setcountry("Republic of Moldova"); } 
            if(country ===  'MC'){ setcountry("Monaco"); } 
            if(country ===  'MN'){ setcountry("Mongolia"); } 
            if(country ===  'ME'){ setcountry("Montenegro"); } 
            if(country ===  'MS'){ setcountry("Montserrat"); } 
            if(country ===  'MA'){ setcountry("Morocco"); } 
            if(country ===  'MZ'){ setcountry("Mozambique"); }
            if(country ===  'MM'){ setcountry("Myanmar"); } 
            if(country ===  'NA'){ setcountry("Namibia"); } 
            if(country ===  'NR'){ setcountry("Nauru"); } 
            if(country ===  'NP'){ setcountry("Nepal"); } 
            if(country ===  'NL'){ setcountry("Netherlands"); }
            if(country ===  'NZ'){ setcountry("New Zealand"); }
            if(country ===  'NI'){ setcountry("Nicaragua"); } 
            if(country ===  'NE'){ setcountry("Niger"); } 
            if(country ===  'NG'){ setcountry("Nigeria"); } 
            if(country ===  'NU'){ setcountry("Niue"); } 
            if(country ===  'NF'){ setcountry("Norfolk Island"); }
            if(country ===  'MP'){ setcountry("Northern Mariana Islands"); } 
            if(country ===  'NO'){ setcountry("Norway"); } 
            if(country ===  'OM'){ setcountry("Oman"); } 
            if(country ===  'PK'){ setcountry("Pakistan"); } 
            if(country ===  'PW'){ setcountry("Palau"); } 
            if(country ===  'PS'){ setcountry("Palestinian Territory"); } 
            if(country ===  'PA'){ setcountry("Panama"); } 
            if(country ===  'PG'){ setcountry("Papua New Guinea"); } 
            if(country ===  'PY'){ setcountry("Paraguay"); } 
            if(country ===  'PE'){ setcountry("Peru"); }
            if(country ===  'PH'){ setcountry("Philippines"); } 
            if(country ===  'PN'){ setcountry("Pitcairn"); } 
            if(country ===  'PL'){ setcountry("Poland"); }
            if(country ===  'PT'){ setcountry("Portugal"); } 
            if(country ===  'PR'){ setcountry("Puerto Rico"); } 
            if(country ===  'QA'){ setcountry("Qatar"); } 
            if(country ===  'RO'){ setcountry("Romania"); } 
            if(country ===  'RU'){ setcountry("Russia"); } 
            if(country ===  'RW'){ setcountry("Rwanda"); } 
            if(country ===  'KN'){ setcountry("Saint Kitts and Nevis"); } 
            if(country ===  'LC'){ setcountry("Saint Lucia"); } 
            if(country ===  'WS'){ setcountry("Samoa"); } 
            if(country ===  'SM'){ setcountry("San Marino"); } 
            if(country ===  'ST'){ setcountry("Sao Tome and Principe"); } 
            if(country ===  'SA'){ setcountry("Saudi Arabia"); } 
            if(country ===  'SN'){ setcountry("Senegal"); } 
            if(country ===  'RS'){ setcountry("Serbia"); } 
            if(country ===  'SC'){ setcountry("Seychelles"); } 
            if(country ===  'SL'){ setcountry("Sierra Leone"); } 
            if(country ===  'SG'){ setcountry("Singapore"); } 
            if(country ===  'SX'){ setcountry("Sint Maarten"); } 
            if(country ===  'SK'){ setcountry("Slovakia"); } 
            if(country ===  'SI'){ setcountry("Slovenia"); } 
            if(country ===  'SB'){ setcountry("Solomon Islands"); } 
            if(country ===  'SO'){ setcountry("Somalia"); } 
            if(country ===  'ZA'){ setcountry("South Africa"); } 
            if(country ===  'SS'){ setcountry("South Sudan"); } 
            if(country ===  'ES'){ setcountry("Spain"); } 
            if(country ===  'LK'){ setcountry("Sri Lanka"); } 
            if(country ===  'SD'){ setcountry("Sudan"); } 
            if(country ===  'SR'){ setcountry("Suriname"); } 
            if(country ===  'SZ'){ setcountry("Swaziland"); } 
            if(country ===  'SE'){ setcountry("Sweden"); } 
            if(country ===  'CH'){ setcountry("Switzerland"); } 
            if(country ===  'SY'){ setcountry("Syria"); } 
            if(country ===  'TW'){ setcountry("Taiwan"); } 
            if(country ===  'TJ'){ setcountry("Tajikistan"); } 
            if(country ===  'TZ'){ setcountry("Tanzania"); } 
            if(country ===  'TH'){ setcountry("Thailand"); } 
            if(country ===  'TG'){ setcountry("Togo"); } 
            if(country ===  'TK'){ setcountry("Tokelau"); } 
            if(country ===  'TO'){ setcountry("Tonga"); } 
            if(country ===  'TT'){ setcountry("Trinidad and Tobago"); } 
            if(country ===  'TN'){ setcountry("Tunisia"); } 
            if(country ===  'TR'){ setcountry("Turkey"); }
            if(country ===  'TM'){ setcountry("Turkmenistan"); } 
            if(country ===  'TC'){ setcountry("Turks and Caicos Islands"); } 
            if(country ===  'TV'){ setcountry("Tuvalu"); } 
            if(country ===  'UG'){ setcountry("Uganda"); } 
            if(country ===  'UA'){ setcountry("Ukraine"); } 
            if(country ===  'AE'){ setcountry("United Arab Emirates"); }
            if(country ===  'GB'){ setcountry("United Kingdom"); } 
            if(country ===  'US'){ setcountry("United States"); } 
            if(country ===  'UY'){ 
            setcountry("Uruguay"); 
            } 
            if(country ===  'UZ'){ 
            setcountry("Uzbekistan"); 
            } 
            if(country ===  'VU'){ 
            setcountry("Vanuatu"); 
            } 
            if(country ===  'VE'){ 
            setcountry("Venezuela, Bolivarian Republic of"); 
            } 
            if(country ===  'VN'){ 
            setcountry("Viet Nam"); 
            } 
            if(country ===  'VI'){ 
            setcountry("Virgin Islands"); 
            } 
            if(country ===  'YE'){ 
            setcountry("Yemen"); 
            } 
            if(country ===  'ZM'){ 
            setcountry("Zambia"); 
            } 
            if(country ===  'ZW'){ 
            setcountry("Zimbabwe")
            }
            
  alert(country)
        }
      }, [firstName])
  */
      useEffect(() => {
          setREF('')
          setREFStatus('')
       }, [updateProfile])

       const handleFormSubmitUpdate = async e => {
        setHandleSubmitBtnUpdate('Loading')
//alert(firstNameDB)
        var formData = new FormData(this)
        formData.append('apiType', 'participantImageUpdate')
        formData.append('participantFile', passportSizePhotoDB)
        formData.append('REF', REF)
        formData.append('category', categoryDB)
        formData.append('firstName', firstNameDB)
        formData.append('lastName', lastNameDB)
        formData.append('phoneNumber', phoneNumberDB)
        formData.append('email', emailDB)
        formData.append('sex', sexDB)
        formData.append('Country', countryDB)
        Axios({
          method: 'post',
          url: `${server_app_api_php}/tcpdf/examples/aaazParticipantEmailUpdate.php`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => {
          setHandleSubmitBtnUpdate('')
          //setBusinessNameSuperAdminDropDown(response.data)
          if(response.data != 'Error: Failed To Update' && response.data !='Error: Failed To Update. Kindly Refresh Your Page And Enter  The Details Once More.'){
            setcategoryDB(response.data.category)
            setfirstNameDB(response.data.firstName)
            setlastNameDB(response.data.lastName)
            setphoneNumberDB(response.data.phoneNumber)
            setemailDB(response.data.email)
            setsexDB(response.data.sex)
            setcountryDB(response.data.country)
            alert('Successfully Updated Profile')
            //alert(response.data)
            if(response.data.country === ''){
              setREF('')
            }
        }
          else{
          alert(response.data)
        }
        }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));

      };


    

  return (
    <section className=''>
      
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
               {/* <Navbar /> */}
               <MDBBtn 
               onClick={handleHome}
               >
               <span className='fa fa-home'> Home</span>
               </MDBBtn>
                  <div className='mt-1'>
                    <h5 className='font-weight-500 my-1 py-1'>
                      APPLICATION FORM
                    </h5>
                  </div>
                  <div class='text-center'>
                  <br />
                    <MDBRow className='row g-3'>
      <MDBBtnGroup>
        Update ID Details ?
      <MDBRadio name='inlineRadio' id='inlineRadio1' value='Yes' label='Yes' inline  onChange={e => setUpdateProfile(e.target.value)} />
      <MDBRadio name='inlineRadio' id='inlineRadio2' value='No' label='No' inline onChange={e => setUpdateProfile(e.target.value)} />
    </MDBBtnGroup>
    </MDBRow>
    <br />
    <hr class='mt-2' />   
                    <MDBRow className='row g-3' hidden = {updateProfile === "Yes"}>        
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
          id='validationCustom02'
          onChange={e => setmiddleName(e.target.value)}
          value={middleName}
          label='Middle Name'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom02'
          onChange={e => setwhatsAppNumber(e.target.value)}
          value={whatsAppNumber}
          label='WhatsApp Number'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom05'
          onChange={e => setage(e.target.value)}
          value={age}
          label='Age'
        />
      </MDBCol>
 
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom02'
          onChange={e => setcity(e.target.value)}
          value={city}
          label='City'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom03'
          onChange={e => setstate(e.target.value)}
          value={state}
          label='State'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
      <ReactFlagsSelect
      searchable
    selected={country}
    onSelect={(code) => setcountry(code)}
  />
      </MDBCol> 

      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom01'
          onChange={e => setoccupation(e.target.value)}
        value={occupation}
          label='Occupation'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom02'
          onChange={e => setorganization(e.target.value)}
          value={organization}
          label='Organization'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom02'
          onChange={e => setsector(e.target.value)}
          value={sector}
          label='Sector'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' hidden>
        <MDBInput
        
          id='validationCustom03'
          onChange={e => setsourceofSponsorship(e.target.value)}
          value={sourceofSponsorship}
          label='Source Of Sponsorship'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12 text-left'>
        <label>Sex</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setsex(e.target.value)}
        value={sex}
        >
            <option value=''>Choose Sex</option>
            <option>Male</option>
            <option>Female</option>
        </select>
      </MDBCol>
      <MDBCol className='col-md-4 col-12 text-left'>
      <label>Participation Category</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setCategory(e.target.value)}
        value={category}
        >
            <option value=''>Choose Category</option>
            <option>Delegate</option>
            <option>Media</option>
            <option>Speaker</option>
            <option>Organiser</option>
            <option>Volunteer</option>
            <option>GRZ Official</option>
            <option>AU Official</option>
            <option>UN Official</option>
            <option>Host</option>
            <option>Diplomat</option>
            <option>Moderator</option>
        </select>
      </MDBCol>

      <MDBCol className='col-md-4 col-12 text-left'>
      <label>Passport Size Photo</label>
        <MDBInput
        type='file'
          id='validationCustom01'
          onChange={savePassportSizePhoto}
        />
      </MDBCol>
      <MDBCol className='col-md-12 col-12' hidden>
        <MDBInput
        
          id='validationCustom05'
          onChange={e => sethowDidYouHearAboutTheSummit(e.target.value)}
          value={howDidYouHearAboutTheSummit}
          label='How Did You Hear About The Summit'
        />
      </MDBCol>

      <MDBCol className='col-md-12 col-12 text-left' hidden>
      <label>Why Do You Want To Participate In The Summit'</label>
        <textarea
        
        className='form-control'
          id='validationCustom01'
          onChange={e => setwhyDoYouWantToParticipateInTheSummit(e.target.value)}
        value={whyDoYouWantToParticipateInTheSummit}
          
        ></textarea>
      </MDBCol>

           <div className='col-12'>
        <MDBBtn onClick={handleFormSubmit}
        disabled={firstName === '' || lastName === '' || sex === '' || email === '' || country === '' || phoneNumber === '' ||
        category === '' || passportSizePhoto === '' || handleSubmitBtn === 'Loading'}
       /* disabled={firstName === '' || lastName === '' ||  phoneNumber === '' ||
        age === '' || sex === '' || email === '' ||
        city === '' || state === '' || Country === '' ||
        occupation === '' || organization === '' || sector === '' || sourceofSponsorship === '' || 
        howDidYouHearAboutTheSummit === '' || whyDoYouWantToParticipateInTheSummit === ''
    }*/
        >
           <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
          Submit</MDBBtn>
      </div>
    </MDBRow>

    <MDBRow className='row g-3' hidden={updateProfile === 'No' || updateProfile === '' || updateProfile === 'undefined' || updateProfile === undefined}>
    <MDBCol className='col-md-12 col-12 text-left' >
    <MDBInput        
        id='validationCustom01'
        onChange={e => setREF(e.target.value)}
      value={REF}
        label='ID Code'
      />
      <p style = {{color: 'red'}} hidden = {REFStatus === 'Correct' || REF === ''}>{REFValidity}</p>
    </MDBCol>
    </MDBRow>

    <MDBRow className='row g-3 mt-1' hidden = {REFStatus != 'Correct' || REF ===''}>        
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setfirstNameDB(e.target.value)}
        value={firstNameDB}
          label='First name'
        />
      </MDBCol>

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setlastNameDB(e.target.value)}
          value={lastNameDB}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setemailDB(e.target.value)}
          value={emailDB}
          label='Email'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setphoneNumberDB(e.target.value)}
          value={phoneNumberDB}
          label='Phone Number'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
      <ReactFlagsSelect
      searchable
    selected={countryDB}
    value={countryDB}
    onSelect={(code) => setcountryDB(code)}
  />
      </MDBCol> 
      <MDBCol className='col-md-4 col-12 text-left'>
        <label>Sex</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setsexDB(e.target.value)}
        value={sexDB}
        >
            <option value=''>Choose Sex</option>
            <option>Male</option>
            <option>Female</option>
        </select>
      </MDBCol>
      <MDBCol className='col-md-4 col-12 text-left'>
      <label>Participation Category</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setcategoryDB(e.target.value)}
        value={categoryDB}
        >
            <option value=''>Choose Category</option>
            <option>Delegate</option>
            <option>Media</option>
            <option>Speaker</option>
            <option>Organiser</option>
            <option>Volunteer</option>
            <option>GRZ Official</option>
            <option>AU Official</option>
            <option>UN Official</option>
            <option>Host</option>
            <option>Diplomat</option>
            <option>Moderator</option>
        </select>
      </MDBCol>

      <MDBCol className='col-md-4 col-12 text-left'>
      <label>Passport Size Photo</label>
        <MDBInput
        type='file'
          id='validationCustom01'
          onChange={savePassportSizePhotoDB}
        />
      </MDBCol>

      <div className='col-12'>
        <MDBBtn onClick={handleFormSubmitUpdate}
        disabled={firstNameDB === '' || lastNameDB === '' || sexDB === '' || emailDB === '' || phoneNumberDB === '' ||
        categoryDB === '' || REF === '' || handleSubmitBtnDB === 'Loading'}
       /* disabled={firstName === '' || lastName === '' ||  phoneNumber === '' ||
        age === '' || sex === '' || email === '' ||
        city === '' || state === '' || Country === '' ||
        occupation === '' || organization === '' || sector === '' || sourceofSponsorship === '' || 
        howDidYouHearAboutTheSummit === '' || whyDoYouWantToParticipateInTheSummit === ''
    }*/
        >
           <div class="spinner-border" role="status" hidden = {handleSubmitBtnUpdate === ''}>
</div>
          Update</MDBBtn>
      </div>

      </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApplicationForm
