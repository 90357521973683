import React, { useState, useEffect } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'

import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import axios from 'axios'
//const axios = require('axios');

function OnlinePointOfSale() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });

   const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/


   const [BusinessName, setBusinessName] = useState('')
  const [BusinessID, setBusinessID] = useState('')
  const [branch, setBranch] = useState('')
  const [pacraID, setPacraID] = useState('')
  const [address, setAddress] = useState('')
  const [ip, setIP] = useState('')
  const [shopType, setShopType] = useState('')
  let [businessCategory, setBusinessCategory] = useState('')
  const [businessCategoryDropDown, setBusinessCategoryDropDown] = useState([])
  const [otherShopCategory, setOtherShopCategory] = useState('')
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  const [logoFile, setLogoFile] = useState()
  const [logoFileName, setLogoFileName] = useState('')
  const [pacraCertificateFile, setPacraCertificateFile] = useState()
  const [pacraCertificateFileName, setPacraCertificateFileName] = useState('')

  
  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  /*useEffect( () => {
    const result = PWD_REGEX.test(pwd);
	setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
},
[pwd, matchPwd])*/

  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  const saveLogoFile = e => {
    setLogoFile(e.target.files[0])
    setLogoFileName(e.target.files[0].name)
  }

  const savePacraCertificateFile = e => {
    setPacraCertificateFile(e.target.files[0])
    setPacraCertificateFileName(e.target.files[0].name)
  }

    useEffect(() => {
    var formDataShopCategory = new FormData(this)
    formDataShopCategory.append('apiType', 'getBusinessCategoryDropDown')
    //Axios.get(`${server_app_api}/api/getBusinessCategoryDropDown`).then(
    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formDataShopCategory,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessCategoryDropDown(response.data)
      //console.log(response);
    })
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var formData = new FormData(this)
    // handle validations
    /*  const logo = e.target.files[0];
  if (logo.size > 1024){
    onLogoFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onLogoFileSelectSuccess(logo);}

  const pacraCertificate = e.target.files[0];
  if (pacraCertificate.size > 1024){
    onPacraCertificateFileSelectError({ error: "File size cannot exceed more than 1MB" });
  }else{ onPacraCertificateFileSelectSuccess(pacraCertificate);}
*/
    formData.append('logoFile', logoFile)
    formData.append('logoFileName', logoFileName)
    formData.append('pacraCertificateFile', pacraCertificateFile)
    formData.append('pacraCertificateFileName', pacraCertificateFileName)

    formData.append('businessName', BusinessName)
    formData.append('businessID', BusinessID)
    formData.append('pacraID', pacraID)
    formData.append('address', address)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('businessCategory', businessCategory)
    formData.append('shopType', shopType)
    formData.append('otherShopCategory', otherShopCategory)
    formData.append('system_setup_IP', ip)

    /*//Axios.post("http://localhost:3001/api/insert", {
		Axios.post("http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php", {
        lname : user, 
        fname : pwd,
    });*/

    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/registerBusiness.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })

    /*Axios.post("http://localhost:3001/api/insertRegisterBusiness", formData).then((res) => {

      alert("Result : "+res);

    })

    .catch((err) => alert("ERROR MESSAGE : "+err));	*/

    /* if(businessCategory ==="Others")
   {
	businessCategory = otherShopCategory;
   }*/
    /*Axios.post(`${server_app_api}/api/insertRegisterBusiness`, {
        
		businessName : BusinessName,
		branch : branch,
		pacraID : pacraID,
		address : address,
		phone : phone,
		email : email,
		businessCategory : businessCategory,
		shopType: shopType,
		system_setup_IP : ip,
		
    })*/
    //.then((res) => {	alert(res.data);

    //})
    //.catch((err) => alert("ERROR : Kindly Contact Your Adminitrator"));
    //.catch((err) => alert("Error : " +err));

    //alert ("Successfully Uploaded");
    /*return(
	<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>
	)*/

    //console.log(user);
    //console.log(pwd);
    //alert ("Successfully Uploaded : " +pacraCertificateFileName);
    //alert(JSON.stringify(formData));
  }

  
  return (
    <>
   
      <Navbar />

      <section class="">
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={50} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      </div>
      </div>
      </section>

{/*<!--Main content-->*/}
<main>


<div class="container">

{/* <!-- Intro Section --> */}
    <section class="view">
      <div class="">
        <div class="">

        {/*   <!-- Grid row --> */}
          <div class="row pt-1">

           {/*  <!-- Grid column --> */}
            <div class="col-md-12">

              <div class="card">
                <div class="card-body">

                  <h2 class="font-weight-bold my-4 text-center mb-5 mt-4 font-weight-bold">
                    <strong>ONLINE POINT OF SALE</strong>
                  </h2>
                  <hr />

                 {/*  <!-- Grid row --> */}
                  <div class="row mt-5">

                   {/*  <!-- Grid column --> */}
                    <div class="col-md-6 ml-lg-5 ml-md-3">

                      {/* <!-- Grid row --> */}
                      <div class="row pb-4">
                        <div class="col-12">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Multiple Store Management</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
                          <li class="mx-3 grey-text nav-item">Create Roles For Employees</li>
                          <li class="mx-3 grey-text nav-item">Access All Store Reports</li>
                          <li class="mx-3 grey-text nav-item">Compare Store Performance</li>
                          <li class="mx-3 grey-text nav-item">Vat Calculation</li>
                          <li class="mx-3 grey-text nav-item">Transfer Inventory</li>
                          <li class="mx-3 grey-text nav-item">Manage Upto 10 Stores</li>
                          <li class="mx-3 grey-text nav-item">Set Different Prices Across Stores</li>
</ul>
                            </p>
                        </div>
                      </div>
                    {/*   <!-- Grid row --> */}

                    {/*   <!-- Grid row --> */}
                      <div class="row pb-4">
                        <div class="col-12">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Business Analysis</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">                          
                          <li class="mx-3 grey-text nav-item">Product Profit Comparison</li>
                          <li class="mx-3 grey-text nav-item">Daily Sales Analysis</li>
                          <li class="mx-3 grey-text nav-item">Daily Profit Analysis</li>
                          <li class="mx-3 grey-text nav-item">Specific Product Analysis</li>
                          <li class="mx-3 grey-text nav-item">Total Stock Count</li>
                          <li class="mx-3 grey-text nav-item">Product Sales Comparison</li>
                          <li class="mx-3 grey-text nav-item">Total Business Value</li>
                          <li class="mx-3 grey-text nav-item">Creates Audit Trails</li>
                          </ul>
                            </p>
                        </div>
                      </div>
                    {/*   <!-- Grid row --> */}

                   {/*    <!-- Grid row --> */}
                      <div class="row pb-4">
                        <div class="col-12">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Business Reports</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left"> 
                          <li class="mx-3 grey-text nav-item">Product Report</li>
                          <li class="mx-3 grey-text nav-item">Inventory Report</li>
                          <li class="mx-3 grey-text nav-item">Sales Report</li>
                          <li class="mx-3 grey-text nav-item">Cash Report</li>
                          <li class="mx-3 grey-text nav-item">Mobile Money Report</li>
                          <li class="mx-3 grey-text nav-item">Users Report</li>
                          <li class="mx-3 grey-text nav-item">Debit Card Report</li>
                          <li class="mx-3 grey-text nav-item">Suppliers Report</li>
                          <li class="mx-3 grey-text nav-item">Credit Sales Report</li>
                          <li class="mx-3 grey-text nav-item">Advanced Security</li>
                          </ul>
                            </p>
                        </div>
                      </div>
                   {/*    <!-- Grid row --> */}

                                      {/*    <!-- Grid row --> */}
                                      <div class="row pb-4">
                        <div class="col-12">
                          <h4 class="font-weight-bold mb-4">
                            <strong>GENERAL</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left"> 
                          <li class="mx-3 grey-text nav-item">Compliance Reports</li>
                          <li class="mx-3 grey-text nav-item">Add Users</li>
                          <li class="mx-3 grey-text nav-item">Add Stock</li>
                          <li class="mx-3 grey-text nav-item">Add Products</li>
                          <li class="mx-3 grey-text nav-item">Priority Support</li>
                          <li class="mx-3 grey-text nav-item">Online Point of Sale</li>
                          <li class="mx-3 grey-text nav-item">Easy to use interface</li>
                          <li class="mx-3 grey-text nav-item">Print and email receipts</li>
                          <li class="mx-3 grey-text nav-item">Export Reports</li>
                          <li class="mx-3 grey-text nav-item">Mobile compatibility</li>
                          </ul>
                            </p>
                        </div>
                      </div>
                   {/*    <!-- Grid row --> */}

                    </div>
                   {/*  <!-- Grid column --> */}

                  {/*   <!-- Grid column --> */}
                    <div class="card col-md-5" >

                  {/*     <!-- Grid row --> */}
                      <div class="row d-flex justify-content-center">

                        <h4 class="d-flex justify-content-center mt-2">
                          <strong>Create Account</strong>
                        </h4>

                      </div>
                      
                        <div class='md-form'>
                          <label>Business Logo </label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            name='logo'
                            id='logo'
                            onChange={saveLogoFile}
                          />
                        </div>
                        
                        <div class='md-form'>
                          <label> Business Registration Certificate</label>
                          <br />
                          <br />
                          <input
                            type='file'
                            class='form-control'
                            name='pacraCertificate'
                            id='pacraCertificate'
                            onChange={savePacraCertificateFile}
                          />
                        </div>
                    
                      
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Business Name <i class='red-text'>*</i>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='BusinessName'
                            id='BusinessName'
                            onChange={e => setBusinessName(e.target.value)}
                            autoComplete='off'
                            value={BusinessName}
                          />
                        </div>
                      

                      
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Business Identification Number{' '}
                            <i class='red-text'>*</i>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='BusinessID'
                            id='BusinessID'
                            onChange={e => setBusinessID(e.target.value)}
                            autoComplete='off'
                            value={BusinessID}
                            required
                          />
                        </div>
                      
                        <div class='md-form'>
                          <label for='registerFullName'>
                            Phone <i class='red-text'>*</i>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='phone'
                            id='phone'
                            onChange={e => setPhone(e.target.value)}
                            autoComplete='off'
                            value={phone}
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                          />
                          <p
                            class='red-text'
                            hidden={
                              phoneFocus=== false || validPhone=== true
                            }
                          >
                            <i class='fa fa-info-circle' />
                            There should be 10 or more, but less than 14
                            numerical values. <br />
                            You can also add + followed by your country or area
                            phone number code.
                          </p>
                        </div>
                      
                     <div class='md-form'>
                          <label>
                            Email <i class='red-text'>*</i>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='email'
                            onChange={e => setEmail(e.target.value)}
                            autoComplete='off'
                            value={email}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          />
                          <p
                            class='red-text'
                            hidden={
                              emailFocus === false || validEmail === true
                            }
                          >
                            <i class='fa fa-info-circle'></i>
                            4 to 24 characters. <br />
                            Let It Be A Valid Email Address.
                          </p>
                        </div>

                     <div class='md-form'>
                          <label>
                            Shop Type <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <br />
                          <select
                            class='custom-select d-block w-100'
                            name='shopType'
                            onChange={e => setShopType(e.target.value)}
                            autoComplete='off'
                            required
                          >
                            <option value=''>Choose ...</option>
                            <option>Retailer</option>
                            <option>Wholeseller</option>
                            <option>Manufacturer</option>
                          </select>
                        </div>

                     <div class='md-form'>
                          <label>
                            Business Category <i class='red-text'>*</i>{' '}
                          </label>
                          <br />
                          <br />
                          <select
                            class='custom-select d-block w-100'
                            name='businessCategory'
                            onChange={e => setBusinessCategory(e.target.value)}
                          >
                            <option value=''>Choose....</option>
                            {businessCategoryDropDown.map(val => {
                              return (
                                <option value={val.shop_categoryID}>
                                  {val.category}{' '}
                                </option>
                              )
                            })}

                            <option>Others</option>
                          </select>
                        </div>
                      
                   <div class='md-form' hidden={businessCategory != 'Others'}>
                          <label>
                            State Your Shop Category <i class='red-text'>*</i>{' '}
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            name='otherShopCategory'
                            onChange={e => setOtherShopCategory(e.target.value)}
                            autoComplete='off'
                            value={otherShopCategory}
                            required
                          />
                        </div>
                     
                     <div class='md-form'>
                          <label for='registerFullName'>
                            Address <i class='red-text'>*</i>{' '}
                          </label>
                          <textarea
                            type='text'
                            class='form-control'
                            name='address'
                            onChange={e => setAddress(e.target.value)}
                            autoComplete='off'
                            value={address}
                            required
                          />
                        </div>

                      <div class="text-center">
                        <button class="btn btn-blue mt-2"
                             onClick={handleSubmit}
                             disabled={
                               BusinessName === '' ||
                               address === '' ||
                               !validPhone ||
                               !validEmail
                             }
                        >Sign up</button>
                      </div>

                    </div>
                {/*     <!-- Grid column --> */}

                  </div>
              {/*     <!-- Grid row --> */}

                </div>
              </div>

            </div>
          {/*   <!-- Grid column --> */}

          </div>
        {/*   <!-- Grid row --> */}

        </div>
      </div>
    </section>
   {/* <!-- Intro Section --> */}

</div>



</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default OnlinePointOfSale;
