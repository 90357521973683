import React, { useState } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'

import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function CompanyRegistration() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });

   const handleClick = ID => {
      localStorage.setItem('tier', JSON.stringify(ID))
      navigate('/CompanyRegistrationPayment')
    

   //const handleSubmitSilver = async e => {
    //navigate('/CompanyRegistrationPayment')
    /*
    var formData = new FormData(this)
            formData.append('apiType', 'payForCompanies')
            formData.append('first_name', 'Rodeen')
            formData.append('last_name', 'Kanchele') 
            formData.append('email', 'rodeen1991@gmail.com')
            formData.append('phone_number', '0979171188')
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/sparco/inc/donate.inc.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              if(response.data.isError === false){
                localStorage.setItem('localUUIDToken', JSON.stringify(response.data.reference))
                alert('Redirecting To Pay Point. During this time, The Processes May Seem To be Delaying, Even After You Confirm Payment, Kindly Wait For It To Load')
                //navigate('../../'+response.data.paymentUrl)
                window.location.href = response.data.paymentUrl;
              //alert(response.data.paymentUrl)
              }
              else{
                alert('Something Went Wrong, Contact Support')
              }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
            */
   }
   
   ;
  
  return (
    <>
    

<main >


<div class="container">

<div class="row mb-5 pb-4">

          <div class="card card-cascade wider reverse mx-3 card">
            {/*<!--Main Navigation-->*/}
<Navbar />
{/*<!--Intro Section-->*/}
<section >
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={100} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      </div>
      </div>
      </section>
{/*<!--Main Navigation-->*/}

{/*<!--Main content-->*/}
  <div class="card-body card-body-cascade text-center">
              <h4 class="card-title"><strong>COMPANY REGISTRATION</strong></h4>
              <p class="card-text">Get started on your journey with Kanch today. Contact us for a free 
              consultation and let us guide you towards success.            
              </p>
            </div>
            </div>
            </div>


            <h2 class="title font-weight-bold d-flex justify-content-center align-items-center my-5 wow fadeIn" data-wow-delay="0.1s">
    <strong>Why Choose Kanch For Company Registration Needs?</strong>
  </h2>

<div class="row mb-4 mt-5">

<div class="col-md-4 mb-4">
<section>             
              <div class="card card-dark">
                <div class="card-body elegant-color white-text rounded-bottom">
                  <h4 class="card-title">No Hidden Costs</h4>
                  <hr class="hr-light" />
 <p class="font-small mb-4">
 Our pricing is transparent, and you can trust us to 
            provide an accurate breakdown of expenses involved.
                    </p>
                </div>
                </div>
             </section>
          </div>

          <div class="col-md-4 mb-4">
<section>             
              <div class="card card-dark">
                <div class="card-body elegant-color white-text rounded-bottom">
                  <h4 class="card-title">Quality Assurance</h4>
                  <hr class="hr-light" />
 <p class="font-small mb-4">
 Our team is committed to delivering high-quality 
            solutions, ensuring your satisfaction with our services.
                    </p>
                </div>
                </div>
             </section>
          </div>

          <div class="col-md-4 mb-4">
<section>             
              <div class="card card-dark">
                <div class="card-body elegant-color white-text rounded-bottom">
                  <h4 class="card-title">Experienced Professionals</h4>
                  <hr class="hr-light" />
 <p class="font-small mb-4">
 Benefit from the expertise of our 
            seasoned professionals who have a deep understanding of the software and legal landscape in Zambia.
                    </p>
                </div>
                </div>
             </section>
          </div>


          </div>
		  

{/*<!-- Section: Pricing v.5 -->*/}
<section class="">
{/*<div class="card card-cascade narrower"> */}
<div class="card card-cascade">
<div class="view view-cascade gradient-card-header "  style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
  {/*<!-- Section heading -->*/}
  <h2 class="card-header-title" >
    <strong>Our Pricing Plans</strong>
  </h2> 

  <p class="text-left">
  Transparent and Competitive Pricing for Software Development and Company Registration. At Kanch, we understand that every business has unique requirements, which is why we offer three tailored 
pricing tiers to suit your specific needs. From Platinum to Gold and Silver, our pricing options ensure that 
you receive the best value for your investment.
    </p>
  
    </div>

    <div class="card-body card-body-cascade text-center">

  {/*<!-- Grid row -->*/}
  <div class="row mt-5">
    {/* <!-- Section description -->*/}
 
   {/* <!-- Grid column -->*/}
    <div class="col-lg-4 col-md-12 mb-5">
    {/*  <!-- Card -->*/}
      <div class="pricing-card card">

    {/*    <!-- Content -->*/}
        <div class="card-body" style={{backgroundColor: 'rgb(132, 132, 140)'}}>
          <h5 class="font-weight-bold mt-3 white-text">SILVER TIER</h5>
       {/*   <!-- Price -->*/}
          <h2 class="font-weight-bold white-text mt-3"><strong>$1,700</strong></h2>
        {/*  <!-- Price -->*/}
          <ul class="list-unstyled striped title light-striped white-text">
            <li>
              <p>
                <strong></strong>Zambia LLC Company Formation Fees (Without Travel)</p>
            </li>
            <li>
              <p>
                <strong></strong>Tax Certificate</p>
            </li>
          </ul>
          <a class="btn pink-gradient btn-rounded mb-4"
          onClick={() => {handleClick('Silver Tier') }}
          > Proceed</a>
        </div>
      </div>
     {/* <!-- Card -->*/}
    </div>
   {/* <!-- Grid column -->*/}

   {/* <!-- Grid column -->*/}
    <div class="col-lg-4 col-md-12 mb-5">

   {/*   <!-- Card -->*/}
      <div class="card card-image" >

      {/*  <!-- Content -->*/}
        <div
          class="text-dark font-weight-bold text-center pricing-card d-flex align-items-center  py-3 px-3 rounded"
          style={{backgroundColor: 'rgb(212, 175, 55)'}}
          >

      {/*    <!-- Content -->*/}
          <div class="card-body">
            <h5 class="font-weight-bold mt-2">GOLD TIER</h5>
       {/*     <!-- Price -->*/}
       <h2 class="font-weight-bold dark-grey-text mt-3"><strong>$2,600</strong></h2>
         {/*   <!-- Price -->*/}
            <ul class="list-unstyled striped light-striped">
              <li>
                <p>
                  <strong></strong> Zambia LLC Company Formation Fees (Without Travel)</p>
              </li>
              <li>
                <p>
                  <strong></strong> Company Secretary and Legal Registered Office Fees</p>
              </li>
              <li>
                <p>
                  <strong></strong> Zambia Corporate Bank Account Opening Fees (Without Travel)</p>
              </li>
              <li>
                <p>
                  <strong></strong> Business Levy</p>
              </li>
              <li>
                <p>
                  <strong></strong> Tax Certificate</p>
              </li>
            </ul>
            <a class="btn btn-rounded btn-outline-black"
            onClick={() => {handleClick('Gold Tier') }}
            > Proceed</a>
          </div>

        </div>
      </div>
     {/* <!-- Card -->*/}
    </div>
    {/*<!-- Grid column -->*/}

   {/* <!-- Grid column -->*/}
    <div class="col-lg-4 col-md-12 mb-5 ">
   {/*   <!-- Card -->*/}
      <div class="pricing-card card" style={{backgroundColor: 'rgba(229, 228, 296)'}}>

      {/*  <!-- Content -->*/}
        <div class="card-body">
          <h5 class="font-weight-bold mt-3">PLATINUM TIER</h5>
      {/*    <!-- Price -->*/}
      <h2 class="font-weight-bold dark-grey-text mt-3"><strong>$3,500</strong></h2>
        {/*  <!-- Price -->*/}
          <ul class="list-unstyled striped title darker-striped black-text font-weight-bold">
            <li>
              <p>
                <strong></strong> Zambia LLC Company Formation Fees (Without Travel)</p>
            </li>
            <li>
              <p>
                <strong></strong> Company Secretary and Legal Registered Office Fees</p>
            </li>
            <li>
              <p>
                <strong></strong> Zambia Corporate Bank Account Opening Fees (Without Travel)</p>
            </li>
            <li>
              <p>
                <strong></strong> Business Levy</p>
            </li>
            <li>
              <p>
                <strong></strong> Tax Certificate</p>
            </li>
            <li>
              <p>
                <strong></strong> VAT Registration</p>
            </li>
            <li>
              <p>
                <strong></strong> 50% Off Bespoke Software Development</p>
            </li>
            <li>
              <p>
                <strong></strong> Zambia Public Procurement Authority Registration</p>
            </li>
            <li>
              <p>
                <strong></strong> Market Research</p>
            </li>
          </ul>
          <a class="btn pink-gradient btn-rounded mb-4 btn-outline-black"
          onClick={() => {handleClick('Platinum Tier') }}
          >Proceed</a>
        </div>

      </div>
     {/* <!-- Card -->*/}
    </div>
   {/* <!-- Grid column -->*/}

  </div>
 {/* <!-- Grid row -->*/}
 </div>
 </div>

</section>
{/* <!-- Section: Pricing v.5 --> */}

</div>



</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default CompanyRegistration;
