import React, { useState } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import '../services_styles.css'
import style from '../homePage_styles.module.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'

import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCarousel,
  MDBCarouselItem
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function HomePage() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });
  
  return (
    <>
    
{/*<!--Main Navigation-->*/}
<header>
<Navbar />
{/*<!--Intro Section-->*/}
<section class="">
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={850} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
  <div class="mask">
    <div class="container h-95 d-flex justify-content-center align-items-center">
      <div class="row flex pt-5 mt-5">

      <div class="col-md-12 col-lg-12 text-center">
          <div class="white-text">
            <h3 class="h1-responsive font-weight-bold wow fadeInLeft" data-wow-delay="0.3s">KANCH INNOVATIVE TECHNOLOGIES </h3>
            <h6 class="wow fadeInLeft" data-wow-delay="0.3s">
            Your Partner in Empowering Business Growth and Facilitating Seamless Expansion into Foreign Markets. 
            </h6>
            {/*}
            <a class="btn btn-outline-white btn-rounded font-weight-bold wow fadeInLeft" data-wow-delay="0.3s">Learn
              more
            </a> */}
          </div>
        </div>

        <div class="col-md-12 col-lg-6 text-left">
          <div class="white-text">
            <h4 class="h1-responsive font-weight-bold wow fadeInLeft" data-wow-delay="0.3s">Reach Out </h4>
            <hr class="hr-light wow fadeInLeft" data-wow-delay="0.3s" />
            <h6 class="wow fadeInLeft" data-wow-delay="0.3s">
            Discover how Kanch can accelerate your business journey today. Explore our services and pricing or 
            reach out to our friendly team for more information. 
            </h6>
            {/*}
            <a class="btn btn-outline-white btn-rounded font-weight-bold wow fadeInLeft" data-wow-delay="0.3s">Learn
              more
            </a> */}
          </div>
        </div>

        <div class="col-md-12 col-lg-6  wow fadeInRight text-left" data-wow-delay="0.1s">
         {/* <img src='https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png' alt='Kanch' class='img-fluid' /> 
         <img src={require('./img/pos1.webp')} alt='pos' class='img-fluid' />*/}
         <div>
          <h4 class="h1-responsive font-weight-bold wow fadeInRight red-text" data-wow-delay="0.1s"> Why Choose Kanch? </h4>
           <hr class="hr-light wow fadeInRight" data-wow-delay="0.1s" /> 
            <h6 class="wow fadeInRight white-text" data-wow-delay="0.1s">
            <strong class='blue-text'>Expert Software Development:</strong> Leverage our skilled team of developers to transform your ideas into robust, 
            scalable software solutions that drive business growth.
            </h6>

            <h6 class="wow fadeInRight white-text" data-wow-delay="0.1s">
            <strong class='blue-text'>Comprehensive Company Registration:</strong> Trust us to handle the complexities of company registration on 
            your behalf, allowing you to expand your business into foreign markets with ease.
            </h6>

            <h6 class="wow fadeInRight white-text" data-wow-delay="0.1s">
            <strong class='blue-text'>Global Reach:</strong> No matter where you are, Kanch is here to serve and 
            guide you towards success in Zambia.
            </h6>
              </div>
        </div>
      </div>
    </div>
   
  </div>
  </div> {/*Dark Mask Ends Here*/}
  </div> {/*POS Image Backgroud Ends Here*/}
</section>

</header>
{/*<!--Main Navigation-->*/}

{/*<!--Main content-->*/}
<main>

{/*<!--First container-->*/}
<div class="container">

  {/*<!--Section: Features v.1-->*/}
  <section id="features" class="mb-5">
    
    {/*<!--Section heading-->*/}
    <h1 class="mb-3 my-5 pt-5 dark-grey-text wow fadeIn text-center" data-wow-delay="0.2s"><strong
        class="font-weight-bold blue-text"></strong> INNOVATE | MANAGE | GROW</h1>

    {/*<!--Section description-->*/}
    <p class="text-left grey-text w-responsive mx-auto mb-1 wow fadeIn" data-wow-delay="0.2s">
    Our Goal Is To Empower Businesses Of All Sizes To
Thrive In Today's Competitive Marketplace. We Believe That By
Providing The Right Tools And Support, We Can Help Our Clients
Achieve Success And Drive Growth In Their Respective
Industries.
</p>

<p class="text-left grey-text w-responsive mx-auto mb-5 wow fadeIn" data-wow-delay="0.2s">
At Kanch, we are more than just a software development company. We are your strategic partner, dedicated to empowering 
your business with cutting-edge technology solutions and facilitating seamless company registration for foreign nationals
 in Zambia. Our value proposition lies in offering top-tier services without the need for you to be physically present 
 in the country. From Platinum to Gold and Silver tiers, our pricing options cater to your specific needs, ensuring a 
 smooth and hassle-free experience.
</p>

<hr />
    {/*<!--First row-->*/}
    <div class="row features wow fadeIn" data-wow-delay="0.2s">
    <h1 class="mb-5 my-5 pt-5 text-center dark-grey-text wow fadeIn" data-wow-delay="0.2s"><strong
        class="font-weight-bold">Our Services </strong></h1>

      <div class="col-lg-6 text-center">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-code blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
          <div class='card-body'>
          <h5 class="color ocean gradient blue-text font-weight-bold mt-2">Software Development</h5>
          <div class="mt-1">
          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> ERP Web-based and Standalone Systems Development</li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Mobile App Development  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>3.</span> E-commerce System Solutions </li>
            </ul>
          </div>
          </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 text-center mb-4">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-book blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
            <div class='card-body'>
          <h5 class="blue-text font-weight-bold mt-2">Company Registration</h5>
          <div class="mt-1">
           <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> Zambia LLC Company Formation </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Company Secretary and Legally Registered Office  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>3.</span> Zambia Corporate Bank Account Opening  </li>
           </ul>
          </div>
          </div>
          </div>
        </div>
      </div>


      </div>

      <div class="row features wow fadeIn" data-wow-delay="0.2s">
      <div class="col-lg-6 text-center mb-4">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-desktop blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
          <div class='card-body'>
          <h5 class="blue-text font-weight-bold mt-2">ICT Infrustracture</h5>
          <div class="mt-1">
            <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> ICT Equipment Supply and Installation</li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> ICT Infrustracture Consultations</li>
            </ul>
          </div>
          </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 text-center">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-network-wired blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
            {/*<div class='card-body' style={{height : '153px'}}>*/}
          <div class='card-body'>
          <h5 class="blue-text font-weight-bold mt-2">Network Services</h5>
          <div class="mt-1">
            <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> Networking Services</li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Cyber Security Services And Consultations</li>
            </ul>
          </div>
          </div>
          </div>
        </div>
      </div>


      
      </div>

      <div class="row features wow fadeIn" data-wow-delay="0.2s">

      <div class="col-lg-6 text-center mb-4">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-chart-area blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
            <div class='card-body'>
          <h5 class="blue-text font-weight-bold mt-2">Business Management</h5>
          <div class="mt-1">
           <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> HR (Human Resources)  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Accounting   </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>3.</span> Taxation   </li>
           </ul>
          </div>
          </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 text-center mb-4">
        <div class="icon-area">
          <div class="circle-icon">
            <i class="fas fa-pencil-alt blue-text"></i>
          </div>
          <br />
          <div class='card mb-3'>
            <div class='card-body'>
          <h5 class="blue-text font-weight-bold mt-2">Marketing and Design</h5>
          <div class="mt-1">
           <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>1.</span> Social Media Management  </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>2.</span> Website Designing   </li>
            <li class="mx-3 grey-text nav-item"> <span class='blue-text'>3.</span> Graphics Designing   </li>
           </ul>
          </div>
          </div>
          </div>
        </div>
      </div>


    </div>
    {/*<!--/First row-->*/}

  </section>
  {/*<!--/Section: Features v.1-->*/}

</div>
{/*<!--First container-->*/}

{/*<!--Second container-->
<div class="container-fluid" style={{backgroundColor: '#f9f9f9'}}>
  <div class="container py-4">

    {/*<!--Section: Download-->
    <section>

      {/*<!-- First row -->
      <div class="row my-4 pt-5 wow fadeIn" data-wow-delay="0.4s">

        {/*<!-- First column -->
        <div class="col-lg-7 col-md-12 mb-4 text-center">
          <img src="https://mdbootstrap.com/img/Photos/Others/screen.jpg" alt=""
            class="img-fluid z-depth-2 rounded" />
        </div>
        {/*<!-- /First column -->

        {/*<!-- Second column -->
        <div class="col-lg-5 col-md-12 mb-4 text-left">

          {/*<!--Section heading-->
          <h2 class="mb-3 my-5 dark-grey-text wow fadeIn" data-wow-delay="0.2s"><strong
              class="font-weight-bold">Download</strong> the application</h2>

          <p class="grey-text mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting let. Lorem
            ipsum dolor sit amet,
            consectetur adipisicing elit.</p>
          <a class="btn btn-white btn-rounded blue-text font-weight-bold ml-0 wow fadeIn" data-wow-delay="0.2s"><i
              class="fab fa-android pr-2" aria-hidden="true"></i> Play store</a>
          <a class="btn btn-white btn-rounded blue-text font-weight-bold wow fadeIn" data-wow-delay="0.2s"><i
              class="fab fa-apple pr-2" aria-hidden="true"></i> App store</a>
        </div>
        {/*<!-- /.Second column -->

      </div>
      {/*<!-- /.First row -->

    </section>
    {/*<!--/Section: Download-->

  </div>
</div>
{/*<!--Second container-->*/}

{/*<!--Third container-->
<div class="streak streak-md streak-photo"
  style={{backgroundImage:"url('https://mdbootstrap.com/img/Photos/Others/architecture.jpg')"}}>
  <div class="flex-center white-text blue-gradient-mask">
    <div class="container py-3">

      {/*<!--Section: Features v.4-->
      <section class="wow fadeIn" data-wow-delay="0.2s">

        {/*<!--Section heading-->
        <h1 class="py-5 my-5 white-text text-center wow fadeIn" data-wow-delay="0.2s"><strong
            class="font-weight-bold">Lorem ipsum</strong> dolor sit amet</h1>

        {/*<!--Grid row-->
        <div class="row features-small mb-5">

          {/*<!--Grid column-->
          <div class="col-md-12 col-lg-4">

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="fas fa-tablet-alt blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Fully responsive</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores.</p>
              </div>
            </div>
            {/*<!--Grid row-->

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="fas fa-level-up-alt blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Frequent updates</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores.</p>
              </div>
            </div>
            {/*<!--Grid row-->

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="fas fa-phone blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Technical support</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores nam.</p>
              </div>
            </div>
            {/*<!--Grid row-->

          </div>
          {/*<!--Grid column-->

          {/*<!--Grid column-->
          <div class="col-md-12 col-lg-4 px-5 mb-2 text-center text-md-left flex-center">
            <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new1.png" alt=""
              class="z-depth-0 img-fluid" />
          </div>
          {/*<!--Grid column-->

          {/*<!--Grid column-->
          <div class="col-md-12 col-lg-4">

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="far fa-object-group blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Editable layout</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores nam.</p>
              </div>
            </div>
            {/*<!--Grid row-->

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="fas fa-rocket blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Fast and powerful</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores nam.</p>
              </div>
            </div>
            {/*<!--Grid row-->

            {/*<!--Grid row-->
            <div class="row mb-5">
              <div class="col-3">
                <a type="button" class="btn-floating white btn-lg my-0"><i class="fas fa-cloud-upload-alt blue-text"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col-9">
                <h5 class="font-weight-bold white-text">Cloud storage</h5>
                <p class="white-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit
                  maiores nam.</p>
              </div>
            </div>
            {/*<!--Grid row-->

          </div>
          {/*<!--Grid column-->

        </div>
        {/*<!--Grid row-->

      </section>
      {/*<!--/Section: Features v.4-->
    </div>
  </div>
</div>
{/*<!--/Third container-->*/}

{/*<!--/Fourth container-->*/}
<div class="container">

  {/*<!--Section: Pricing v.3-->
  <section class="mt-4 mb-5">

    {/*<!--Section heading-->
    <h1 class="mb-3 my-5 pt-5 text-center dark-grey-text wow fadeIn" data-wow-delay="0.2s"><strong
        class="font-weight-bold">Lorem ipsum</strong> dolor sit amet</h1>

    {/*<!--Section description-->
    <p class="text-center w-responsive mx-auto my-5 grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing
      elit. Fugit, error amet numquam iure provident voluptate
      esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</p>

    {/*<!--First row-->
    <div class="row">

      {/*<!--First column-->
      <div class="col-lg-4 col-md-12 mb-4">
        {/*<!--Card-->
        <div class="card">

          {/*<!--Content-->
          <div class="text-center">
            <div class="card-body">
              <h5>Basic plan</h5>
              <div class="d-flex justify-content-center">
                <div class="card-circle d-flex justify-content-center align-items-center">
                  <i class="fas fa-home blue-text"></i>
                </div>
              </div>

              {/*<!--Price-->
              <h2 class="font-weight-bold dark-grey-text mt-3"><strong>59$</strong></h2>
              <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa pariatur id nobis
                accusamus
                deleniti cumque hic laborum.</p>
              <a class="btn btn-blue font-weight-bold btn-rounded">Buy now</a>
            </div>
          </div>

        </div>
        {/*<!--/.Card-->
      </div>
      {/*<!--/First column-->

      {/*<!--Second column-->
      <div class="col-lg-4 col-md-12 mb-4">
        {/*<!--Card-->
        <div class="card blue-gradient">

          {/*<!--Content-->
          <div class="text-center white-text">
            <div class="card-body">
              <h5>Premium plan</h5>
              <div class="d-flex justify-content-center">
                <div class="card-circle d-flex justify-content-center align-items-center">
                  <i class="fas fa-users white-text"></i>
                </div>
              </div>

              {/*<!--Price-->
              <h2 class="font-weight-bold white-text mt-3"><strong>79$</strong></h2>
              <p>Esse corporis saepe laudantium velit adipisci cumque iste ratione facere non distinctio
                cupiditate sequi atque.</p>
              <a class="btn btn-white font-weight-bold btn-rounded">Buy now</a>
            </div>
          </div>

        </div>
        {/*<!--/.Card-->
      </div>
      {/*<!--/Second column-->

      {/*<!--Third column-->
      <div class="col-lg-4 col-md-12 mb-4">
        {/*<!--Card-->
        <div class="card">

          {/*<!--Content-->
          <div class="text-center">
            <div class="card-body">
              <h5>Advanced plan</h5>
              <div class="d-flex justify-content-center">
                <div class="card-circle d-flex justify-content-center align-items-center">
                  <i class="fas fa-chart-bar blue-text"></i>
                </div>
              </div>

              {/*<!--Price-->
              <h2 class="font-weight-bold dark-grey-text mt-3"><strong>99$</strong></h2>
              <p class="grey-text">At ab ea a molestiae corrupti numquam quo beatae minima ratione magni accusantium
                repellat
                eveniet quia vitae.</p>
              <a class="btn btn-blue font-weight-bold btn-rounded">Buy now</a>
            </div>
          </div>

        </div>
        {/*<!--/.Card-->
      </div>
      {/*<!--/Third column-->

    </div>
    {/*<!--/First row-->

  </section>
  {/*<!--/Section: Pricing v.3-->*/}

  <hr />


  {/*<!--Section: Testimonials v.4-->*/}
  <section class="text-center pb-4">

    {/*<!--Section heading-->*/}
    <h1 class="mb-5 my-5 pt-5 text-center dark-grey-text wow fadeIn" data-wow-delay="0.2s"><strong
        class="font-weight-bold">Our clients</strong></h1>

    <div class="row">

      {/*<!--Carousel Wrapper-->*/}
      <div id="multi-item-example" class="carousel testimonial-carousel slide carousel-multi-item mb-5"
        data-ride="carousel">

        {/*<!--Controls-->*/}
        <div class="controls-top">
          <a class="btn-floating btn-blue btn-sm" href="#multi-item-example" data-slide="prev"><i
              class="fas fa-chevron-left"></i></a>
          <a class="btn-floating btn-blue btn-sm" href="#multi-item-example" data-slide="next"><i
              class="fas fa-chevron-right"></i></a>
        </div>
        {/*<!--Controls-->*/}

        {/*<!--Slides-->*/}
        <div class="carousel-inner" role="listbox">

          {/*<!--First slide-->*/}
          <div class="carousel-item active">

            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                    <img src={require('./img/clients logos/royal mobile.JPG')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Royal Mobile Store</h6>           
              </div>
            </div>
            {/*<!--Grid column-->*/}

            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                <img src={require('./img/clients logos/amadoda.JPG')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Amadoda Pamlina Pharmacy</h6>
              </div>
            </div>
            {/*<!--Grid column-->*/}

            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                <img src={require('./img/clients logos/kwiq.jpg')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Kwiq Petroleum</h6>
              </div>
            </div>
            {/*<!--Grid column-->*/}

          </div>
          {/*<!--First slide-->*/}

          {/*<!--Second slide-->*/}
          <div class="carousel-item">

            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                <img src={require('./img/clients logos/metro security.JPG')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Metro Security Services</h6>
              </div>
            </div>
            {/*<!--Grid column-->*/}

            
            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                <img src={require('./img/clients logos/finegrade chemicals.jpg')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Finegrade Chemicals</h6>
              </div>
            </div>
            {/*<!--Grid column-->*/}

            
            {/*<!--Grid column-->*/}
            <div class="col-md-4">
              <div class="testimonial">
                {/*<!--Avatar-->*/}
                <div class="avatar mx-auto">
                <img src={require('./img/clients logos/equator beverages solutions.jpg')} className='rounded-circle img-fluid w-100' />
                </div>
                {/*<!--Content-->*/}
                <h6 class="blue-text font-weight-bold my-3">Equator Beverages Solutions</h6>
              </div>
            </div>
            {/*<!--Grid column-->*/}
          </div>
          {/*<!--Second slide-->*/}

                    {/*<!--Second slide-->*/}
                    <div class="carousel-item">

{/*<!--Grid column-->*/}
<div class="col-md-4">
  <div class="testimonial">
    {/*<!--Avatar-->*/}
    <div class="avatar mx-auto">
    <img src={require('./img/clients logos/AAAZ.jpg')} className='rounded-circle img-fluid w-100' />
    </div>
    {/*<!--Content-->*/}
    <h6 class="blue-text font-weight-bold my-3">Alliance For Accountability Advocates Zambia</h6>
  </div>
</div>
{/*<!--Grid column-->*/}

</div>
{/*<!--Second slide-->*/}

        </div>
        {/*<!--Slides-->*/}

      </div>
      {/*<!--Carousel Wrapper-->*/}

    </div>

  </section>
  {/*<!--Section: Testimonials v.4-->*/}

</div>
{/*<!--/Fourth container-->*/}

</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default HomePage;
