import React, { useState } from 'react';
import {
  MDBNavbar,  MDBContainer,  MDBNavbarBrand,  MDBNavbarToggler,  MDBNavbarItem, MDBCardText,
  MDBNavbarLink,  MDBIcon,  MDBCollapse,  MDBTabs,  MDBTabsItem,  MDBTabsLink, MDBCardTitle,MDBBtn
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'

export default function Navbar() {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()
/*
var userType = localStorage.getItem('userType')
userType = JSON.parse(userType)

var position = localStorage.getItem('position')
position = JSON.parse(position)

    var loggedIn = sessionStorage.getItem('loggedIn')
    loggedIn = JSON.parse(loggedIn)
    if(loggedIn != 'Yes'){
      navigate('/Logout')
    }

  const [showNavExternal, setShowNavExternal] = useState(false);
*/

  return (
    <>
    {/*<!--Navbar-->*/}
<nav class="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar"  >
  <div class="container">
  <a class="navbar-brand" href="#"><strong id='companyName'>KANCH INNO TECH</strong></a> 
  {/*  
  <a class="navbar-brand" href="#">
  <img src={require('./img/clients logos/kanch white logo.jpg')} height={30} />
    </a>
    */}
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
      aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent-7">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href=".">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="Services">Services and Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Register</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Partners</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">News</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Careers</a>
        </li>

      </ul>
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light"><i class="fab fa-twitter"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light"><i class="fab fa-google-plus-g"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light"><i class="fab fa-facebook-f"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light"><i class="fab fa-linkedin"></i></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
{/*<!--/Navbar-->*/}
    </>
  );
}