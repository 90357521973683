import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
const CompleteRegistration = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)
    
    const [handleSubmitBtnLoaderOne, setHandleSubmitBtnLoaderOne] = useState('')
    const [handleSubmitBtnLoader, setHandleSubmitBtnLoader] = useState('')
    let [participantsFile, setParticipantsFile] = useState('')
    const saveParticipantsFile = e => {
        setParticipantsFile(e.target.files[0])
      }

      useEffect(() => {
      //if(handleSubmitBtnLoaderOne === ''){
       //setHandleSubmitBtnLoader('Loading')
      //}
    }, [handleSubmitBtnLoaderOne])

    const handleFormSubmit = async e => {
      setHandleSubmitBtnLoaderOne('Loading')
      
        
            var formData = new FormData(this)
            formData.append('apiType', 'participantImage')
            formData.append('participantFile', participantsFile)
            formData.append('id', userID)
            formData.append('userType', userType)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/tcpdf/examples/aaazParticipantEmail.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                setHandleSubmitBtnLoader('')
              //setBusinessNameSuperAdminDropDown(response.data)
              if(response.data === 'Participants Registered successfully'){
              //setParticipantsFile('')
            }
              if(response.data != ''){
              alert(response.data)
            }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity")
            );
         
      };

  return (
    <>        

    <section className=''>
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
                <Navbar />
                  <div className='mt-3'>
                    <h5 className='font-weight-500 my-1 py-1'>
                      Add Passport Size Photo
                    </h5>
                  </div>
                  <div class='text-center'>
                    <hr class='mt-1' />
                    <MDBRow className='row g-3'>
      <MDBCol className='col-md-12 col-12 text-left'>
        <label>Photo</label>
        <MDBInput
        type='file'
          id='validationCustom01'
          onChange={saveParticipantsFile}
          accept="image/*"
          
        />
      </MDBCol>
      
      <div className='col-12'>
        <MDBBtn onClick={handleFormSubmit}
        disabled={participantsFile === ''}
        >
          <div class="spinner-border" role="status" hidden = {handleSubmitBtnLoader === ''}>
</div>
          Submit</MDBBtn>
      </div>
    </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default CompleteRegistration
