import React, { useState } from 'react';
import Navbar from './Views/Navbar'
import Footer from './Views/Footer'
import $ from 'jquery'
import WOW from 'wowjs';
//import logo from './logo.svg';
import '../App.css';
import './styles/css/bootstrap.min.css'
import './styles/css/mdb.min.css'
//import './styles/js/jquery-3.4.1.min.js'
//import './styles/js/popper.min.js'
//import './styles/js/bootstrap.min.js'
//import './styles/js/mdb.min.js'

import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function Services() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

   //Animation init
   //new WOW().init();
   new WOW.WOW().init();

   //Modal
   $('#myModal').on('shown.bs.modal', function () {
     $('#myInput').focus()
   })

   // Material Select Initialization
   $(document).ready(function () {
     //$('.mdb-select').formSelect();
   });
  
  return (
    <>
   
      <Navbar />

      <section class="">
<div className='bg-image'>
<img src={require('./img/pos1.webp')} className='w-100' height={50} />
<div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      </div>
      </div>
      </section>

{/*<!--Main content-->*/}
<main>


<div class="container">

 {/*<!-- Intro Section -->*/}
 <section class="">
      <div class="">
        <div class="">

       {/*   <!-- Grid row -->*/}
          <div class="row pt-5">

          {/*  <!-- Grid column -->*/}
            <div class="col-md-12">

              <div class="card">
                <div class="card-body">

                  <h2 class="font-weight-bold my-4 text-center mb-5 mt-4 font-weight-bold">
                    <strong>SERVICES</strong>
                  </h2>
                  <h5 class="text-center mb-5 w-responsive mx-auto">Our Comprehensive Services for 
                  Your Business Success</h5>
                  <p class="text-left mb-5 w-responsive mx-auto">
At Kanch, we offer a wide range of services to empower your business and help it thrive in the digital age. From software 
development to human resources, accounting, taxation, and more, our expert team is dedicated to fulfilling 
your unique requirements. <br />
We take pride in being a one-stop solution for all your business needs. Let us help you achieve your
 goals with our comprehensive services and personalized approach.
</p>
                  <hr />

               {/*   <!-- Grid row -->*/}
                  <div class="row mt-5">

                  {/*  <!-- Grid column -->*/}
                    <div class="col-md-6 ml-lg-5 ml-md-3">

                   {/*   <!-- Grid row -->*/}
                      <div class="row pb-4">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-code blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Software Development</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. ERP Web-based and Standalone Systems Development</h6>
            Build responsive and user-friendly web applications tailored to your business needs.
            </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. Mobile App Development </h6>Reach a wider audience with custom mobile applications for Android and iOS platforms. </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>3. E-commerce System Solutions </h6> Set up a seamless online store to boost your sales and expand your market reach. </li>
            </ul>
                            </p>
                        </div>
                      </div>
                    {/*  <!-- Grid row -->*/}

                     {/* <!-- Grid row -->*/}
                      <div class="row pb-4">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-book blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Company Registration</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. Zambia LLC Company Formation </h6> Register your company in Zambia without the need for physical presence.</li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. Company Secretary and Legally Registered Office </h6> Ensure compliance with local regulations with our company secretary services.  </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>3. Zambia Corporate Bank Account Opening </h6> Facilitate smooth banking operations with professional assistance. </li>
           </ul>
                            </p>
                        </div>
                      </div>
                     {/* <!-- Grid row -->*/}

                     {/* <!-- Grid row -->*/}
                      <div class="row pb-4">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-desktop blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>ICT Infrustracture</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. ICT Equipment Supply and Installation </h6> We Supply and Install ICT equipment. </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. ICT Infrustracture Consultations</h6> We provide ICT consultation services.</li>
            </ul>
                            </p>
                        </div>
                      </div>
                     {/* <!-- Grid row -->*/}

                    </div>
                   {/* <!-- Grid column -->*/}

                   {/* <!-- Grid column -->*/}
                    <div class="col-md-5">

                    {/*   <!-- Grid row -->*/}
                    <div class="row pb-2">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-network-wired blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Network Services</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. Networking Services </h6> We Supply, Install, and Configure Computer Networking equipment, to enable You access through interconectivity of your ICT resources within and outside your institution.</li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. Cyber Security Services And Consultations </h6> We provide consultation and cyber security services including penetration testing to ensure the security of your ICT infrastracture and Data security, for both Online and Offline network systems.  </li>
            </ul>
                            </p>
                        </div>
                      </div>
                    {/*  <!-- Grid row -->*/}

                     {/*   <!-- Grid row -->*/}
                     <div class="row pb-2">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-chart-area blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Business Management</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. HR (Human Resources) </h6> Streamline your HR processes with our efficient solutions for talent acquisition and management. </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. Accounting </h6> Keep your finances in order with our expert accounting services and reporting.  </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>3. Taxation </h6> Stay compliant with local tax regulations and optimize your tax strategies for maximum efficiency.  </li>
           </ul>
                            </p>
                        </div>
                      </div>
                    {/*  <!-- Grid row -->*/}

                     {/*   <!-- Grid row -->*/}
                     <div class="row pb-4">
                        <div class="col-2 col-lg-1">
                          <i class="fas fa-pencil-alt blue-text fa-lg"></i>
                        </div>
                        <div class="col-10">
                          <h4 class="font-weight-bold mb-4">
                            <strong>Marketing and Design</strong>
                          </h4>
                          <p class="">
                          <ul class="navbar-nav mr-auto text-left">
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>1. Social Media Management </h6> Enhance your online presence and engage with your target audience through effective social media management. </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>2. Website Designing </h6> Create a captivating and functional website that represents your brand and converts visitors into customers.  </li>
            <li class="mx-3 grey-text nav-item"> <h6 class='blue-text'>3. Graphics Designing  </h6> Elevate your brand identity with visually appealing graphics for marketing materials. </li>
           </ul>
                            </p>
                        </div>
                      </div>
                    {/*  <!-- Grid row -->*/}

                    </div>
                   {/* <!-- Grid column -->*/}

                  </div>
                 {/* <!-- Grid row -->*/}

                </div>
              </div>

            </div>
            {/*<!-- Grid column -->*/}

          </div>
         {/* <!-- Grid row -->*/}

        </div>
      </div>
    </section>
   {/* <!-- Intro Section --> */}

</div>



</main>
{/*<!--Main content-->*/}


<Footer />
    </>
  );
}

export default Services;
