import React, { useState } from 'react';
import {
  MDBNavbar,  MDBContainer,  MDBNavbarBrand,  MDBNavbarToggler,  MDBNavbarItem, MDBCardText,
  MDBNavbarLink,  MDBIcon,  MDBCollapse,  MDBTabs,  MDBTabsItem,  MDBTabsLink, MDBCardTitle,MDBBtn
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'

export default function Footer() {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()
    const handleClickSystemsDevelopment = ID => {
      if(ID === 'ERP Web-based and Standalone Systems'){
        localStorage.setItem('systemsDevelopment', JSON.stringify('ERP Web-based and Standalone Systems'))
        navigate('/SystemsDevelopment')
      }
      if(ID === 'Mobile Applications'){
        localStorage.setItem('systemsDevelopment', JSON.stringify('Mobile Applications'))
        navigate('/SystemsDevelopment')
      }
      if(ID === 'Websites'){
        localStorage.setItem('systemsDevelopment', JSON.stringify('Websites'))
        navigate('/SystemsDevelopment')
      }
      if(ID === 'Networking Services'){
        localStorage.setItem('systemsDevelopment', JSON.stringify('Networking Services'))
        navigate('/SystemsDevelopment')
      }
      if(ID === 'ICT Equipment Supply and Installation'){
        localStorage.setItem('systemsDevelopment', JSON.stringify('ICT Equipment Supply and Installation'))
        navigate('/SystemsDevelopment')
      }
      
    }
/*
var userType = localStorage.getItem('userType')
userType = JSON.parse(userType)

var position = localStorage.getItem('position')
position = JSON.parse(position)

    var loggedIn = sessionStorage.getItem('loggedIn')
    loggedIn = JSON.parse(loggedIn)
    if(loggedIn != 'Yes'){
      navigate('/Logout')
    }

  const [showNavExternal, setShowNavExternal] = useState(false);
*/

  return (
    <>
 {/*<!--Footer-->*/}
<footer class="page-footer  text-md-left lighten-5 pt-1 db-color ocean blue-gradient" >

{/*}
<div style={{backgroundColor: '#5991fb'}}>
  <div class="container">
    {/*<!--Grid row-->
    <div class="row py-4 d-flex align-items-center">

      {/*<!--Grid column-->
      <div class="col-12 col-md-5 text-left mb-md-0">
        <h6 class="mb-0 white-text text-center text-md-left"><strong>Get connected with us on social
            networks!</strong></h6>
      </div>
      {/*<!--Grid column-->

      {/*<!--Grid column-->
      <div class="col-12 col-md-7 text-center text-md-right">
        {/*<!--Facebook-->
        <a class="p-2 m-2 fa-lg fb-ic ml-0"><i class="fab fa-facebook-f white-text mr-lg-4"> </i></a>
        {/*<!--Twitter-->
        <a class="p-2 m-2 fa-lg tw-ic"><i class="fab fa-twitter white-text mr-lg-4"> </i></a>
        {/*<!--Google +-->
        <a class="p-2 m-2 fa-lg gplus-ic"><i class="fab fa-google-plus-g white-text mr-lg-4"> </i></a>
        {/*<!--Linkedin-->
        <a class="p-2 m-2 fa-lg li-ic"><i class="fab fa-linkedin-in white-text mr-lg-4"> </i></a>
        {/*<!--Instagram-->
        <a class="p-2 m-2 fa-lg ins-ic"><i class="fab fa-instagram white-text mr-lg-4"> </i></a>
      </div>
      {/*<!--Grid column-->

    </div>
    {/*<!--Grid row-->
  </div>
</div>
*/}

{/*<!--Footer Links-->*/}
<div class="container mt-5 mb-4 text-center text-md-left" >
  <div class="row mt-3">

    {/*<!--First column-->
    <div class="col-md-3 col-lg-4 col-xl-3 mb-4 white-text">
      <h6 class="text-uppercase font-weight-bold"><strong>Kanch Innovative Technologies</strong></h6>
      <hr class="white mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}} />
      <p>
        Kanch Innovative Technologies Is a Zambian Registered Limited Company, In Accordance To The Laws Of Zambia 
        Under PACRA. 
        The Company Specialises In Software Development And ICT Dealership. 
      </p>
    </div>
    {/*<!--/.First column-->*/}

    {/*<!--Second column-->*/}
    <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4 white-text text-left">
      <h6 class="text-uppercase font-weight-bold"><strong>Software Development</strong></h6>
      <hr class="white mb-4 mt-0 d-inline-block mx-auto" style={{width: '300px'}} />
      <p><a href="" class="white-text" type='button' onClick={() => {handleClickSystemsDevelopment('ERP Web-based and Standalone Systems') }}>ERP Web-based and Standalone Systems</a></p>
      <p><a href="" class="white-text" type='button' onClick={() => {handleClickSystemsDevelopment('Mobile Applications') }}>Mobile Applications</a></p>
      <p><a href="" class="white-text" type='button' onClick={() => {handleClickSystemsDevelopment('Websites') }}>Websites</a></p>
      <p><a href="" class="white-text" type='button' onClick={() => {handleClickSystemsDevelopment('Networking Services') }}>Networking Services</a></p>
      <p><a href="" class="white-text" type='button' onClick={() => {handleClickSystemsDevelopment('ICT Equipment Supply and Installation') }}>ICT Equipment Supply and Installation</a></p>
      <p><a href="CompanyRegistration" class="white-text">Company Registration</a></p>
    </div>
    {/*<!--/.Second column-->*/}

    {/*<!--Third column-->*/}
    <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4 white-text  text-left">
      <h6 class="text-uppercase font-weight-bold"><strong>Our Other Products</strong></h6>
      <hr class="white mb-4 mt-0 d-inline-block mx-auto" style={{width: '300px'}} />
      <p><a href="OnlinePointOfSale" class="white-text">Online Point Of Sale</a></p>
      <p><a href="#!" class="white-text">Zam SDA Sings</a></p>
      <p><a href="#!" class="white-text">E - Village Banking Records Management</a></p>
      <p><a href="#!" class="white-text">E - Events Management And Ticketing</a></p>
      <p><a href="#!" class="white-text">E - Commerce</a></p>
      <p><a href="#!" class="white-text">E - Real Estate</a></p>
      <p><a href="#!" class="white-text">E - Company Registration Assistance</a></p>
    </div>
    {/*<!--/.Third column-->*/}

    {/*<!--Fourth column-->*/}
    <div class="col-md-4 col-lg-4 col-xl-4 white-text  text-left">
      <h6 class="text-uppercase font-weight-bold"><strong>Get In Touch With Us</strong></h6>
      <hr class="white mb-4 mt-0 d-inline-block mx-auto" style={{width: '300px'}} />
     <p><i class="fas fa-map-marker-alt fa-1x title mr-3"></i> B4/16 off Kamloops Road, Lusaka, Zambia</p>
    <p><i class="fas fa-phone mr-3"></i> +260 972 898 813</p>
    <p><i class="fas fa-phone mr-3"></i> +260 969 536 263</p>
    <p><i class="fas fa-phone mr-3"></i> +260 760 040 837</p>
    <p><i class="fas fa-phone mr-3"></i> +260 979 171 188</p>
    <p><i class="fas fa-envelope mr-3"></i> <i>sales@kanchtech.com</i></p>
    <p><i class="fas fa-envelope mr-3"></i> <i>support@kanchtech.com</i></p>    
    <p><i class="fas fa-envelope mr-3"></i> <i>info@kanchtech.com</i></p>
    </div>
    {/*<!--/.Fourth column-->*/}

  </div>
</div>
{/*<!--/.Footer Links-->*/}

{/*<!-- Copyright-->*/}
<div class="footer-copyright py-3 text-center">
  <div class="container-fluid">
    © <a href="https://www.kanchtech.com" target="_blank"> Kanch Inno Tech </a>
  </div>
</div>
{/*<!--/.Copyright -->*/}

</footer>
{/*<!--/Footer-->*/}
    </>
  );
}