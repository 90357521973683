import { useRef, useState, useEffect, useContext } from 'react'
const Missing = () => {
  return (
    <section class='view intro-2'>
      <title>Error - 404</title>
      <div class='rgba-stylish-strong h-100 d-flex justify-content-center align-items-center'>
        <div class='container'>
          <div class='row'>
            <div class='col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div class='card wow fadeIn' data-wow-delay='0.3s'>
                <div class='card-body'>
                  <div class='form-header purple-gradient'>
                    <h3 class='font-weight-500 my-2 py-1'>
                      404 <br /> This Page Does Not Exist:
                    </h3>
                  </div>
                  <div class='text-center'>
                    {' '}
                    <hr class='mt-4' />{' '}
                    <p>
                      {' '}
                      <a href='/' class='red-text'>
                        {' '}
                        Home
                      </a>{' '}
                    </p>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Missing
