import { useRef, useState, useEffect, useContext } from 'react'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow
  } from 'mdb-react-ui-kit';
  import { useNavigate} from 'react-router-dom'
  import Axios from 'axios'
  import Navbar from './Views/Navbar'
const ApplicationForm = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    let navigate = useNavigate()

    var userType = localStorage.getItem('userType')
    userType = JSON.parse(userType)
    
    var userID = localStorage.getItem('userID')
    userID = JSON.parse(userID)

    let [firstName, setFirstName] = useState('')
    let [middleName, setmiddleName] = useState('')
    let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')    
    let [age, setage] = useState('')
    let [sex, setsex] = useState('')
    let [whatsAppNumber, setwhatsAppNumber] = useState('')
    let [city, setcity] = useState('')
    let [state, setstate] = useState('')
    let [Country, setCountry] = useState('')
    let [occupation, setoccupation] = useState('')
    let [organization, setorganization] = useState('')
    let [sector, setsector] = useState('')
    let [sourceofSponsorship, setsourceofSponsorship] = useState('')
    let [howDidYouHearAboutTheSummit, sethowDidYouHearAboutTheSummit] = useState('')
    let [whyDoYouWantToParticipateInTheSummit, setwhyDoYouWantToParticipateInTheSummit] = useState('')

    const handleFormSubmit = async e => {
            var formData = new FormData(this)
            formData.append('apiType', 'applicationForm')
            formData.append('firstName', firstName)
            formData.append('middleName', middleName) 
            formData.append('lastName', lastName)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)   
            formData.append('age', age)
            formData.append('sex', sex)
            formData.append('whatsAppNumber', whatsAppNumber)
            formData.append('city', city)
            formData.append('state', state)
            formData.append('Country', Country)
            formData.append('occupation', occupation)
            formData.append('organization', organization)
            formData.append('sector', sector)
            formData.append('sourceofSponsorship', sourceofSponsorship)
            formData.append('howDidYouHearAboutTheSummit', howDidYouHearAboutTheSummit)
            formData.append('whyDoYouWantToParticipateInTheSummit', whyDoYouWantToParticipateInTheSummit)
            Axios({
              method: 'post',
              url: `${server_app_api_php}/reactphp/index.php`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
              //setBusinessNameSuperAdminDropDown(response.data)
              if(response.data === 'Upload Completed Successfully. When Your Payment Is Processed, Your Will Receive A Comfirmation Email'){
              setFirstName('')              
              setmiddleName('') 
              setLastName('')
              setPhoneNumber('')
              setEmail('')  
              setage('')
              setsex('')
              setwhatsAppNumber('')
              setcity('')
              setstate('')
              setCountry('')
              setoccupation('')
              setorganization('')
              setsector('')
              setsourceofSponsorship('')
              sethowDidYouHearAboutTheSummit('')
              setwhyDoYouWantToParticipateInTheSummit('')
              alert(response.data)
            }
              else{
              alert(response.data)
            }
            }) .catch((err) => alert("Connetion Error : Check Internet Connectivity"));
         
      };

  return (
    <section className=''>
      
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
                <Navbar />
                  <div className='mt-1'>
                    <h5 className='font-weight-500 my-1 py-1'>
                      Apply
                    </h5>
                  </div>
                  <div class='text-center'>
                    <hr class='mt-2' />
                    <MDBRow className='row g-3'>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setmiddleName(e.target.value)}
          value={middleName}
          label='Middle Name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setwhatsAppNumber(e.target.value)}
          value={whatsAppNumber}
          label='WhatsApp Number'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom05'
          onChange={e => setage(e.target.value)}
          value={age}
          label='Age'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setsex(e.target.value)}
        value={sex}
        >
            <option value=''>Choose Sex</option>
            <option>Male</option>
            <option>Female</option>
        </select>
      </MDBCol>
 
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setcity(e.target.value)}
          value={city}
          label='City'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setstate(e.target.value)}
          value={state}
          label='State'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom05'
          onChange={e => setCountry(e.target.value)}
          value={Country}
          label='Country'
        />
      </MDBCol>

      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setoccupation(e.target.value)}
        value={occupation}
          label='Occupation'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setorganization(e.target.value)}
          value={organization}
          label='Organization'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setsector(e.target.value)}
          value={sector}
          label='Sector'
        />
      </MDBCol>
      <MDBCol className='col-md-4 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setsourceofSponsorship(e.target.value)}
          value={sourceofSponsorship}
          label='Source Of Sponsorship'
        />
      </MDBCol>
      <MDBCol className='col-md-12 col-12' >
        <MDBInput
          id='validationCustom05'
          onChange={e => sethowDidYouHearAboutTheSummit(e.target.value)}
          value={howDidYouHearAboutTheSummit}
          label='How Did You Hear About The Summit'
        />
      </MDBCol>

      <MDBCol className='col-md-12 col-12 text-left'>
      <label>Why Do You Want To Participate In The Summit'</label>
        <textarea
        
        className='form-control'
          id='validationCustom01'
          onChange={e => setwhyDoYouWantToParticipateInTheSummit(e.target.value)}
        value={whyDoYouWantToParticipateInTheSummit}
          
        ></textarea>
      </MDBCol>
           <div className='col-12'>
           <MDBBtn onClick={handleFormSubmit}
   >Submit</MDBBtn>
            {/*}
        <MDBBtn onClick={handleFormSubmit}
        disabled={firstName === '' || lastName === '' ||  phoneNumber === '' || email === '' ||
        age === '' || sex === '' || email === '' ||
        city === '' || state === '' || Country === '' ||
        occupation === '' || organization === '' || sector === '' || sourceofSponsorship === '' || 
        howDidYouHearAboutTheSummit === '' || whyDoYouWantToParticipateInTheSummit === ''
    }
        >Submit</MDBBtn>
        */}
      </div>
    </MDBRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApplicationForm
